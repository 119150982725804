export const ACCESS_APPLICATION = 'main:view';

export const MAIN_USERS_VIEW = 'main:users:view';
export const MAIN_ORGANIZATIONS_VIEW = 'main:organizations:view';
export const MAIN_VENDORS_VIEW = 'main:vendors:view';
export const MAIN_SETTINGS_VIEW = 'main:settings:view';

export const MAIN_ORDERS_VIEW = 'main:orders:view';
export const MAIN_MEALS_VIEW = 'main:meals:view';

export const MAIN_PLATFORM_VIEW = 'main:platform:view';
