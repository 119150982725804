import React, { useState } from 'react';
import { getVendorAvailability } from '@/services/api/vendors';
import Button from '@/components/Button';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { translations } from '@/locale';
import Modal from '@/components/Modal';
import TimeOffModal from '@/components/Modal/TimeOffModal';
import LoadData from '@/components/LoadData';
import Loading from '@/components/Loading';
import If from '@/components/If';
import Placeholder from '@/components/Placeholder';
import Table from '@/components/Table';
import { VendorAvailability } from '@/domains/availability';
import Grid from '@/components/Grid';

export interface Props {
  id: string;
}

const VendorAvailabilityTab: React.FC<Props> = ({ id }) => {
  const [availability, setAvailability] = useState<VendorAvailability>();

  return (
    <LoadData id={id} load={getVendorAvailability}>
      {({ value: availability, loading, reload }) => (
        <If
          condition={loading}
          then={() => (
            <Loading visible={true} center className="h-full">
              <Loading.Indicator size={60} borderWidth={4} />
            </Loading>
          )}
          else={() => (
            <If
              condition={!availability}
              then={() => (
                <Placeholder
                  className="h-full justify-center"
                  image="bell"
                  size="xs"
                  title="Oups"
                  description="Vendor not found. Please try again later."
                />
              )}
              else={() => {
                return (
                  <React.Fragment>
                    <Grid>
                      <div>
                        <h2 id="payment-details-heading" className="text-lg leading-6 font-medium text-gray-900">
                          Scheduled time off
                        </h2>
                        <p className="mt-1 text-sm text-gray-500">
                          {"Add scheduled breaks when the vendor doesn't take orders."}
                        </p>
                      </div>

                      <Table>
                        {availability.breaks.map((e) => {
                          return (
                            <Table.Row key={e.id}>
                              <Table.Data>
                                <span>
                                  <FormattedDate value={e.startDate} day="2-digit" month="long" year="numeric" />{' '}
                                  {' - '}
                                  <FormattedDate value={e.endDate} day="2-digit" month="long" year="numeric" />
                                </span>
                              </Table.Data>
                              <Table.Data>
                                <Button className="ml-4" appearance="red">
                                  Delete
                                </Button>
                              </Table.Data>
                            </Table.Row>
                          );
                        })}
                      </Table>

                      <Modal modal={TimeOffModal} vendorId={id} onSaved={reload}>
                        {({ open }) => (
                          <Button appearance="ghost" icon="plus" className="mt-4" onClick={open}>
                            Add time off
                          </Button>
                        )}
                      </Modal>
                    </Grid>
                  </React.Fragment>
                );
              }}
            />
          )}
        />
      )}
    </LoadData>
  );
};

export default VendorAvailabilityTab;
