import classNames from 'classnames';
import React from 'react';

interface Props {
  active: boolean;
}

const Status: React.FC<Props> = ({ active }) => (
  <span
    className={classNames(
      'px-2 inline-flex text-xs leading-5 font-semibold rounded-full',
      active ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-400'
    )}
  >
    {active ? 'Active' : 'Inactive'}
  </span>
);

export default Status;
