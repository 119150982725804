import React from 'react';
import classNames from 'classnames';

import { Image } from '@/domains';

import { Props as InputProps } from '@/components/Input';
import { withValidation } from '@/components/hoc/withValidation';
import FileUpload from '@/components/FileUpload';
import { generateImageId } from '@/util';
import Button from '@/components/Button';

export interface Props extends InputProps {
  image?: Image;
  onImageUploaded: (image: Image) => void;
  label?: string;
  required?: boolean;
}

const readAsDataUrl = (file: File) =>
  new Promise<Image>((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', () => {
      resolve({ id: generateImageId('l'), url: reader.result as string, thumbnailUrl: reader.result as string, file });
    });
  });

const ImageInput = (props: React.PropsWithChildren<Props>) => {
  const { className, image, label, placeholder, required = false, value } = props;
  return (
    <div className={classNames('space-y-6', className)}>
      <div className="sm:col-span-6">
        {label && (
          <div className="text-sm font-medium text-gray-700">
            <label htmlFor={props.id} className="inline-block pb-2 text-gray-dark">
              {label}
            </label>

            {required && <span className="text-red ml-1">*</span>}
          </div>
        )}

        <div className="mt-1 flex items-center">
          <img
            className="inline-block bg-gray-100 object-cover h-24 w-24 rounded-lg"
            src={value?.url}
            alt="profile-image"
          />
          <div className="ml-4 flex">
            <FileUpload
              accept="image/x-png,image/jpeg"
              onChange={(files: FileList) => {
                if (files.length > 0) {
                  readAsDataUrl(files[0]).then((image) => {
                    props.onImageUploaded(image);
                  });
                }
              }}
            >
              <Button
                is="div"
                className="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500"
              >
                {placeholder ?? 'Select Image'}
              </Button>
            </FileUpload>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageInput;
export const ImageInputWithValidation = withValidation(ImageInput);
