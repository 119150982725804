import { Order, Page } from '@/domains';
import { formatISO } from 'date-fns';

import { authorizedApi } from '@/services/network/authorized-api';

export const getOrders = (
  params: {
    term: string;
    vendorIds: string[];
    startDate: Date;
    endDate: Date;
  },
  page: number,
  size: number
) =>
  authorizedApi.get<Page<Order>>(`/admin/orders`, {
    term: params.term,
    pageNumber: page,
    pageSize: size,
    vendorIds: params.vendorIds,
    startDate: formatISO(params.startDate, { representation: 'date' }),
    endDate: formatISO(params.endDate, { representation: 'date' })
  });

export const exportOrders = (params: { term: string; vendorIds: string[]; startDate: Date; endDate: Date }) =>
  authorizedApi
    .get(
      `/admin/orders/export`,
      {
        term: params.term,
        vendorIds: params.vendorIds,
        startDate: formatISO(params.startDate, { representation: 'date' }),
        endDate: formatISO(params.endDate, { representation: 'date' })
      },
      {
        responseType: 'arraybuffer'
      }
    )
    .then((response: any) => {
      console.log(response);
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'export.xlsx';
      link.click();
    });
