import React from 'react';
import { Field, FieldProps } from 'react-final-form';

const hasOwnProperty = Object.prototype.hasOwnProperty;

// TODO: research on a better way how to exclude functions from prop shallow comparison
function shallowEqualWithoutFunctions(prevObj: any, nextObj: any) {
  if (prevObj === nextObj) {
    return true;
  }

  const prevKeys = Object.keys(prevObj);
  const nextKeys = Object.keys(nextObj);
  const len = prevKeys.length;

  if (len !== nextKeys.length) {
    return false;
  }

  let i = -1;

  while (++i < len) {
    const key = prevKeys[i];

    if (!hasOwnProperty.call(nextObj, key)) {
      return;
    }

    const prev = prevObj[key];
    const next = nextObj[key];

    if (typeof prev === `function` && typeof next === `function`) {
      continue;
    }

    if (prev !== next) {
      return false;
    }
  }

  return true;
}

const ExcludeFunctionsPropsFromShallowComparison: React.SFC<any> = React.memo(
  ({ is: Component, ...rest }) => <Component {...rest} />,
  (prevProps, nextProps) => {
    return !!shallowEqualWithoutFunctions(prevProps, nextProps);
  }
);

export interface Props<T> extends FieldProps<any, any> {
  name: string;
  type?: string;
  is: React.ComponentType<T>;
  [x: string]: any;
  onFocus?: (event: Event) => any;
  onBlur?: (event: Event) => any;
  onChange?: (event: Event) => any;
}

export default class FormField<T> extends React.PureComponent<Props<T>> {
  static defaultProps = {
    onFocus: () => true,
    onBlur: () => true,
    onChange: () => true
  };

  handler = (fieldCallback: (event: any) => any, propCallback: (event: any) => any) => (event: Event) => {
    fieldCallback(event);
    if (propCallback) propCallback(event);
  };

  render() {
    const { name, type, value, isEqual, ...rest } = this.props;

    return (
      <Field name={name} value={value} type={type} isEqual={isEqual}>
        {({ input: { onFocus, onBlur, onChange, ...inputRest }, meta }) => {
          const invalid = meta.submitError ? !!meta.submitError && !meta.dirtySinceLastSubmit : meta.invalid;
          const errorVisible = !meta.active && meta.touched && invalid;
          const errorMessage = errorVisible ? meta.error || meta.submitError : null;

          return (
            <ExcludeFunctionsPropsFromShallowComparison
              name={type}
              type={type}
              invalid={errorVisible}
              error={errorMessage}
              {...rest}
              {...inputRest}
              // TODO: research on a better way how to exclude functions from prop shallow comparison
              onFocus={this.handler(onFocus, this.props.onFocus!)}
              onBlur={this.handler(onBlur, this.props.onBlur!)}
              onChange={this.handler(onChange, this.props.onChange!)}
            />
          );
        }}
      </Field>
    );
  }
}
