import React from 'react';
import classnames from 'classnames';

import Loading from '@/components/Loading';

import style from './Button.sass';

export type ButtonAppearance = 'orange' | 'red' | 'blue' | 'ghost' | 'ghostBlue' | 'accentFill';

export interface Props extends React.ButtonHTMLAttributes<any> {
  is?: any;
  appearance?: ButtonAppearance;
  block?: boolean;
  loading?: boolean;
  [x: string]: any;
  fat?: boolean;
}

export default class Button extends React.PureComponent<Props> {
  static defaultProps: Partial<Props> = {
    is: 'button',
    appearance: null,
    loading: null
  };

  render() {
    const {
      is: Component,
      appearance,
      block,
      loading,
      disabled,
      className,
      fat,
      children,
      onClick,
      ...rest
    } = this.props;

    return (
      <Component
        className={classnames(
          style.root,
          {
            [style.block]: block,
            [style.loading]: loading,
            [style.disabled]: disabled,
            [style.base]: appearance,
            [style.fat]: fat
          },
          style[appearance!],
          className
        )}
        disabled={loading || disabled}
        onClick={disabled ? null : onClick}
        {...rest}
      >
        {!!loading && (
          <span className={style.loader}>
            <Loading visible={!!loading} center>
              <Loading.Indicator size={24} borderWidth={3} />
            </Loading>
          </span>
        )}

        <span className={style.content}>{children}</span>
      </Component>
    );
  }
}
