import * as yup from 'yup';

import { translations } from '@/locale';
import { MAX_FILE_SIZE_IN_MB, STRING_LONG_MAX_LENGTH } from '@/constants';

export const emailSchema = yup.string().email().max(STRING_LONG_MAX_LENGTH).label(translations.inputs.email.label);

export const phoneNumberSchema = yup
  .string()
  .matches(/^[0-9 +()-]+$/, translations.validation.custom.phoneNumber)
  .label(translations.inputs.phoneNumber.label);

export const imageSchema = yup.mixed().test({
  message: translations.validation.custom.fileSizeExceeded,
  name: 'fileSizeExceded',
  test: function (value) {
    const isParentArray = Array.isArray(this.parent);
    let index: number;
    if (isParentArray) {
      index = parseInt(this.path.match(/\[[0-9]+\]$/)[0]?.replace(/(\[|\])/g, ''));
    }
    if (!value.file) return true;
    if (value && value.file.size > MAX_FILE_SIZE_IN_MB * 1024 * 1024) {
      throw this.createError({
        path: this.path,
        params: { index: isParentArray ? index + 1 : undefined, size: MAX_FILE_SIZE_IN_MB }
      });
    }
    return true;
  }
});

export const timeSchema = yup.string().matches(/^[0-9]{2}:[0-9]{2}$/, translations.validation.custom.time);
