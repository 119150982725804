import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { debounce } from 'lodash';
import { DateRange } from 'react-date-range';

import { exportOrders, getOrders } from '@/services/api/orders';

import Typography from '@/components/Typography';
import { translations } from '@/locale';
import PaginatedTable from '@/components/PaginatedTable';
import Table from '@/components/Table';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Dropdown from '@/components/Dropdown';
import { getDateFormat, getDateRangeToString } from '@/util';
import VendorSelectionDropdown from './VendorSelectionDropdown';
import { Order } from '@/domains';
import Placeholder from '@/components/Placeholder';
import { Link } from 'react-router-dom';
import { urls } from '@/constants';

const text = translations.pages.orders.list;

export interface Props {}

const OrdersListPage: React.FC<Props> = () => {
  const [state, setState] = useState({
    term: '',
    vendors: [],
    selectedRange: {
      startDate: new Date(),
      endDate: new Date(),
      key: 'range'
    }
  });

  return (
    <React.Fragment>
      <div className="flex justify-between mb-4">
        <Typography is="h1" type="header">
          <FormattedMessage id={text.title} />
        </Typography>

        <button
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
          onClick={() => {
            exportOrders({
              term: state.term,
              vendorIds: state.vendors.map((vendor) => vendor.id),
              startDate: state.selectedRange.startDate,
              endDate: state.selectedRange.endDate
            });
          }}
        >
          <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
          </svg>
          <span>Download Report</span>
        </button>
      </div>

      <div className="flex justify-between">
        <FilterBar onChange={(term) => setState({ ...state, term })} />

        <div className="flex space-x-2">
          <Dropdown
            dropdownClassName="mt-2 right-0"
            renderMenu={() => (
              <VendorSelectionDropdown
                vendors={state.vendors}
                onVendorIdsChanged={(vendors) => setState({ ...state, vendors })}
              />
            )}
          >
            {({ open }) => (
              <Button onClick={open} className="border h-12 rounded-full px-4 bg-white hover:bg-gray-200">
                {state.vendors.length > 0 ? `${state.vendors.length} vendors selected` : 'All vendors'}
              </Button>
            )}
          </Dropdown>

          <Dropdown
            dropdownClassName="mt-2 right-0"
            renderMenu={({ close }) => (
              <DateRange
                className="shadow rounded-md"
                editableDateInputs={true}
                onChange={(item) =>
                  setState({
                    ...state,
                    selectedRange: {
                      startDate: item[state.selectedRange.key].startDate,
                      endDate: item[state.selectedRange.key].endDate,
                      key: item[state.selectedRange.key].key
                    }
                  })
                }
                moveRangeOnFirstSelection={false}
                ranges={[state.selectedRange]}
              />
            )}
          >
            {({ open }) => (
              <Button onClick={open} className="border h-12 rounded-full px-4 bg-white hover:bg-gray-200">
                {getDateRangeToString(state.selectedRange.startDate, state.selectedRange.endDate)}
                <Icon type="chevronDown" />
              </Button>
            )}
          </Dropdown>
        </div>
      </div>

      <PaginatedTable
        key={JSON.stringify(state)}
        className="mt-4"
        source={(page, size) =>
          getOrders(
            {
              term: state.term,
              vendorIds: state.vendors.map((vendor) => vendor.id),
              startDate: state.selectedRange.startDate,
              endDate: state.selectedRange.endDate
            },
            page,
            size
          )
        }
        empty={() => (
          <Placeholder
            image="cards"
            size="sm"
            title="No orders found"
            description={
              'There are no orders for ' +
              getDateRangeToString(state.selectedRange.startDate, state.selectedRange.endDate)
            }
          />
        )}
        renderHeader={() => (
          <React.Fragment>
            <Table.Header>PIN</Table.Header>
            <Table.Header>Items</Table.Header>
            <Table.Header>User</Table.Header>
            <Table.Header>Paid</Table.Header>
            <Table.Header>Expected Delivery</Table.Header>
            <Table.Header>Placed On</Table.Header>
          </React.Fragment>
        )}
      >
        {(data) => (
          <React.Fragment>
            {data.map((order: Order) => (
              <Table.Row key={order.id}>
                <Table.Data>
                  {order.pin}
                  <Typography is="p">{order.status}</Typography>
                </Table.Data>
                <Table.Data>
                  {order.items.map((item) => (
                    <p key={item.id}>
                      {item.count}x {item.title}
                    </p>
                  ))}
                </Table.Data>
                <Table.Data>
                  <Link to={urls.users.detail.get(order.user.id)} className="text-blue-800 hover:underline">
                    {order.user.firstName} {order.user.lastName}
                  </Link>
                </Table.Data>
                <Table.Data>{order.totalPrice} RON</Table.Data>
                <Table.Data>{getDateFormat(order.pickupDate.toString())}</Table.Data>
                <Table.Data>{getDateFormat(order.createdAt.toString())}</Table.Data>
              </Table.Row>
            ))}
          </React.Fragment>
        )}
      </PaginatedTable>
    </React.Fragment>
  );
};

export interface FilterProps {
  onChange: (term: string) => void;
}

const FilterBar: React.FC<FilterProps> = ({ onChange }) => {
  const handler = useCallback(debounce(onChange, 300), []);

  return (
    <input
      type="text"
      placeholder="Filter by PIN or Customer ..."
      className="px-3 py-3 w-72 rounded-md bg-white border-transparent focus:border-gray-500 focus:ring-0 text-sm border border-gray-300"
      onChange={(e) => handler(e.target.value)}
    />
  );
};

export default OrdersListPage;
