import React from 'react';

import { MealCategory } from '@/domains';
import { getMealCategories } from '@/services/api/category';

interface CategoriesState {
  categories: MealCategory[];
  loading: boolean;
}

export const CategoriesContext = React.createContext<CategoriesState>({} as any);

interface Props {}
interface State extends Readonly<CategoriesState> {}

export const CategoriesConsumer = CategoriesContext.Consumer;

class Provider extends React.PureComponent<Props, State> {
  state: State = {
    categories: [],
    loading: false
  };

  refreshCategories() {
    getMealCategories()
      .then((categories) => this.setState({ categories, loading: false }))
      .catch(() => null);
  }

  componentDidMount() {
    this.refreshCategories();
  }

  render() {
    return <CategoriesContext.Provider value={this.state}>{this.props.children}</CategoriesContext.Provider>;
  }
}

export const CategoriesProvider = Provider;

export default {
  CategoriesProvider,
  CategoriesConsumer,
  CategoriesContext
};
