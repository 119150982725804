import React, { useState } from 'react';

import PaginatedTable from '@/components/PaginatedTable';
import Table from '@/components/Table';
import { FormattedMessage } from 'react-intl';
import { translations } from '@/locale';
import Status from '@/components/Status';
import Refresh from '@/components/Refresh';
import SearchBar from '@/components/SearchBar';
import Modal from '@/components/Modal';
import InviteVendorMemberModal from '@/components/Modal/InviteVendorMemberModal';
import { Me } from '@/domains';
import Placeholder from '@/components/Placeholder';
import { fetchUsers } from '@/services/api/users';

export interface Props {
  id: string;
}

const VendorMembersTab: React.FC<Props> = ({ id }) => {
  const [term, setTerm] = useState('');

  return (
    <Refresh>
      {({ refresh, key }) => (
        <React.Fragment>
          <div className="flex justify-between pb-4">
            <SearchBar onChange={setTerm} placeholder={'Filter by name or email ...'} showFilter={false} />
            <Modal modal={InviteVendorMemberModal} vendorId={id} onMemberInvited={refresh}>
              {({ open }) => (
                <button
                  type="button"
                  className="relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  onClick={open}
                >
                  Invite member
                </button>
              )}
            </Modal>
          </div>

          <PaginatedTable
            key={JSON.stringify({ key, term })}
            source={(page, size) => fetchUsers(page, size, { vendorId: id, organisationId: null, term: term })}
            empty={() => (
              <Placeholder
                className="h-full justify-center my-4"
                image="bell"
                size="xs"
                title="No members found."
                description="This vendor has no members yet. You can start adding members by tapping the Invite members button above."
              />
            )}
            renderHeader={() => (
              <React.Fragment>
                <Table.Header>Name</Table.Header>
                <Table.Header>Email</Table.Header>
                <Table.Header>Role</Table.Header>
                <Table.Header>Status</Table.Header>
              </React.Fragment>
            )}
          >
            {(data) => (
              <React.Fragment>
                {data.map((member: Me) => (
                  <Table.LinkRow key={member.id}>
                    <Table.Data>
                      {member.firstName} {member.lastName}
                    </Table.Data>
                    <Table.Data>{member.email}</Table.Data>
                    <Table.Data>
                      <FormattedMessage id={translations.roles[member.role]} />
                    </Table.Data>
                    <Table.Data>
                      <Status active={member.active} />
                    </Table.Data>
                  </Table.LinkRow>
                ))}
              </React.Fragment>
            )}
          </PaginatedTable>
        </React.Fragment>
      )}
    </Refresh>
  );
};

export default VendorMembersTab;
