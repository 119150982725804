import React from 'react';
import * as yup from 'yup';

import Typography from '@/components/Typography';
import LoadData from '@/components/LoadData';
import Loading from '@/components/Loading';
import If from '@/components/If';
import { getOrganization } from '@/services/api/organizations';
import Placeholder from '@/components/Placeholder';

const schema: yup.ObjectSchema = yup
  .object({
    dailyMenuVendorId: yup.string().label('Daily Menu'),
    coffeeVendorId: yup.string().label('Coffee Vendor')
  })
  .required();

export interface Props {
  id: string;
}

const OrganizationExclusiveVendorsTab: React.FC<Props> = ({ id }) => {
  return (
    <React.Fragment>
      <div>
        <Typography is="h1" type="subheader">
          Exclusive vendors
        </Typography>
        <Typography is="p" type="sm">
          Add exclusive vendors to your organization to provide specific products and services to your customers.
        </Typography>
      </div>

      <LoadData id={id} load={getOrganization}>
        {({ value: organization, loading }) => (
          <If
            condition={loading}
            then={() => (
              <Loading visible={loading} center className="h-full">
                <Loading.Indicator size={60} borderWidth={4} />
              </Loading>
            )}
            else={() => (
              <If
                condition={!organization}
                then={() => (
                  <Placeholder
                    className="h-full justify-center"
                    image="bell"
                    size="xs"
                    title="Oups"
                    description="Organization not found. Please try again later."
                  />
                )}
                else={() => (
                  <React.Fragment>
                    <div className="relative flex flex-col pt-4">
                      <div className="text-md font-medium">
                        Cofee vendor: {organization.coffeeVendor.name ?? 'Not set'}
                      </div>
                    </div>
                    <div className="relative flex flex-col pt-4">
                      <div className="text-md font-medium">
                        Daily Menu vendor: {organization.dailyMenuVendor.name ?? 'Not set'}
                      </div>
                    </div>
                  </React.Fragment>
                )}
              />
            )}
          />
        )}
      </LoadData>
    </React.Fragment>
  );
};

export default OrganizationExclusiveVendorsTab;
