import { AssetType, Me, Page } from '@/domains';
import { AddTimeOff, VendorAvailability } from '@/domains/availability';
import { Vendor, VendorAdd, VendorMemberAdd, VendorProfileUpdate } from '@/domains/vendor';
import { formatISO } from 'date-fns';
import { authorizedApi } from '../network/authorized-api';
import { uploadImage } from './media';

export const getVendors = (page: number, size: number, term: string) =>
  authorizedApi.get<Page<Vendor>>(`/admin/vendors`, { pageNumber: page, pageSize: size, term });

export const inviteVendor = (model: VendorAdd) => authorizedApi.post('/admin/vendors', model);

export const getVendor = (id: string): Promise<Vendor> => authorizedApi.get(`/admin/vendors/${id}`);

export const updateVendorProfile = (id: string, data: VendorProfileUpdate) => {
  const { image, ...rest } = data;

  return uploadImage(image, AssetType.VendorImage).then(({ imageUrl, thumbnailUrl }) => {
    return authorizedApi.patch(`admin/vendors/${id}`, {
      ...rest,
      imageUrl,
      thumbnailUrl
    });
  });
};

export const inviteVendorMember = (id: string, data: VendorMemberAdd) =>
  authorizedApi.post<Me>(`/admin/vendors/${id}/members`, { ...data });

// Availability

export const getVendorAvailability = (id: string) =>
  authorizedApi.get<VendorAvailability>(`/admin/vendors/${id}/availability`);

export const addVendorTimeOff = (id: string, data: AddTimeOff) =>
  authorizedApi.post(`admin/vendors/${id}/time-off`, {
    startDate: formatISO(data.startDate, { representation: 'date' }),
    endDate: formatISO(data.endDate, { representation: 'date' })
  });
