import * as reduce from 'image-blob-reduce';

import { AssetType, Image } from '@/domains';

import { httpClient } from '@/services/network/http';
import { authorizedApi } from '@/services/network/authorized-api';

interface PresignedFileUrl {
  url: string;
  fields: any;
  assetUrl: string;
}

interface ImageUploadResult {
  imageUrl: string;
  thumbnailUrl: string;
}

const uploadFileToS3 = (file: File) => (presignedFileUrl: PresignedFileUrl) => {
  const { fields, url, assetUrl } = presignedFileUrl;
  const form = Object.entries(fields).reduce((form, [key, value]) => {
    form.append(key, value as any);
    return form;
  }, new FormData());

  form.append('file', file, file.name);

  return httpClient.post(url, form, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => assetUrl);
};

const getPresignedFileUrl = (filename: string, type: AssetType) =>
  authorizedApi.get<PresignedFileUrl>('/presigned-file-urls', null, {
    params: { type, filename }
  });

export const uploadImage = async (image: Image, type: AssetType): Promise<ImageUploadResult> => {
  if (!image.file) return Promise.resolve({ imageUrl: image.url, thumbnailUrl: image.thumbnailUrl });

  const thumbnailUrl = await getPresignedFileUrl(`thumb_${image.file.name}`, type).then(uploadFileToS3(image.file));
  const imageUrl = await getPresignedFileUrl(image.file.name, type).then(uploadFileToS3(image.file));

  return {
    imageUrl,
    thumbnailUrl
  };
};

export const uploadImagesOfType = (images: Image[], type: AssetType) => {
  const promises = images.map((image) => uploadImage(image, type));
  return Promise.all(promises);
};
