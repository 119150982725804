import React from 'react';
import classnames from 'classnames';

export interface Props {
  is?: React.ElementType;
  className?: string;
  hoverable?: boolean;
  [x: string]: any;
}

const Row: React.FC<Props> = ({ is: Component = 'tr', className, hoverable, children, ...rest }) => (
  <Component className={classnames({ 'hover:bg-gray-100 hover:bg-opacity-50': hoverable }, className)} {...rest}>
    {children}
  </Component>
);

export default Row;
