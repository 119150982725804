import React from 'react';
import { parse } from 'query-string';
import * as yup from 'yup';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { SignUp as SignUpDomain } from '@/domains';
import { signUpWithInvitation } from '@/services/api/users';
import { translations } from '@/locale';

import Button from '@/components/Button';
import { SubmitError } from '@/components/Error';
import Form from '@/components/Form';
import { PasswordInputWithValidation } from '@/components/Input/PasswordInput';
import { TextInputWithValidation } from '@/components/Input/TextInput';

import logo from '@/assets/logo.svg';

const schema: yup.ObjectSchema<SignUpDomain> = yup.object({
  firstName: yup.string().label(translations.inputs.firstName.label).required(),
  lastName: yup.string().label(translations.inputs.lastName.label).required(),
  email: yup.string().label(translations.inputs.email.label).required(),
  password: yup.string().label(translations.inputs.password.label).required(),
  invitationToken: yup.string().label(translations.inputs.language.label).required()
});

export interface Props extends RouteComponentProps {}

const AcceptInvitationPage: React.FC<Props & WrappedComponentProps> = (props) => {
  const email = parse(location.search).email as string;
  const invitationToken = parse(location.search).invitationToken as string;

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={logo} alt="Workflow" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign up</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Form
            id="sign-in"
            className="space-y-6"
            schema={schema}
            initialValues={{ email, invitationToken }}
            subscription={{ submitError: true, submitting: true, values: true }}
            onSubmit={(values) =>
              signUpWithInvitation(
                values.firstName,
                values.lastName,
                values.email,
                values.password,
                values.invitationToken
              )
            }
          >
            {({ submitError, submitting, values }, id) => (
              <div className="space-y-6">
                <Form.Field
                  is={TextInputWithValidation}
                  id={`${id}-firstName`}
                  name="firstName"
                  type="name"
                  label={props.intl.formatMessage({ id: translations.inputs.firstName.label })}
                  placeholder={props.intl.formatMessage({ id: translations.inputs.firstName.label })}
                  readOnly={submitting}
                />

                <Form.Field
                  is={TextInputWithValidation}
                  id={`${id}-lastName`}
                  name="lastName"
                  type="name"
                  label={props.intl.formatMessage({ id: translations.inputs.lastName.label })}
                  placeholder={props.intl.formatMessage({ id: translations.inputs.lastName.label })}
                  readOnly={submitting}
                />

                <Form.Field
                  is={TextInputWithValidation}
                  id={`${id}-email`}
                  name="email"
                  type="email"
                  label={props.intl.formatMessage({ id: translations.inputs.email.label })}
                  placeholder={props.intl.formatMessage({ id: translations.inputs.email.placeholder })}
                  readOnly={true}
                />

                <Form.Field
                  is={PasswordInputWithValidation}
                  id={`${id}-password`}
                  name="password"
                  label={props.intl.formatMessage({ id: translations.inputs.password.label })}
                  placeholder={props.intl.formatMessage({ id: translations.inputs.password.placeholder })}
                  readOnly={submitting}
                  className="mt-4"
                />

                {!!submitError && <SubmitError error={submitError} className="mb-4" />}

                <Button type="submit" className="w-full" loading={submitting} appearance="orange">
                  Sign Up
                </Button>
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(AcceptInvitationPage);
