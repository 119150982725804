import { MealCategory, Promotion, UpdatePromotion } from '@/domains';
import { authorizedApi } from '../network/authorized-api';

export const getPromotions = (): Promise<Promotion[]> => authorizedApi.get<Promotion[]>('/promotions');

export const createPromotion = (data: UpdatePromotion): Promise<Promotion> =>
  authorizedApi.post<Promotion>('/promotions', data);

export const updatePromotion = (id: string, data: UpdatePromotion): Promise<Promotion> =>
  authorizedApi.patch(`/promotions/${id}`, data);

export const deletePromotion = (id: string): Promise<void> => authorizedApi.delete(`/promotions/${id}`);
