import React, { useState } from 'react';

import PaginatedTable from '@/components/PaginatedTable';
import Table from '@/components/Table';
import { FormattedMessage } from 'react-intl';
import { translations } from '@/locale';
import Status from '@/components/Status';
import Modal from '@/components/Modal';
import InviteOrgMemberModal from '@/components/Modal/InviteOrgMemberModal';
import Refresh from '@/components/Refresh';
import SearchBar from '@/components/SearchBar';
import { Me } from '@/domains';
import Icon from '@/components/Icon';
import Dropdown from '@/components/Dropdown';
import Button from '@/components/Button';
import Typography from '@/components/Typography';
import { fetchUsers } from '@/services/api/users';

export interface Props {
  id: string;
}

const OrganizationMembersTab: React.FC<Props> = ({ id }) => {
  const [term, setTerm] = useState('');

  return (
    <Refresh>
      {({ refresh, key }) => (
        <React.Fragment>
          <div className="flex justify-between pb-4">
            <SearchBar onChange={setTerm} placeholder={'Filter by name or email ...'} showFilter={false} />
            <Modal modal={InviteOrgMemberModal} orgId={id} onMemberInvited={refresh}>
              {({ open }) => (
                <Button appearance="ghost" onClick={open}>
                  Invite member
                </Button>
              )}
            </Modal>
          </div>

          <PaginatedTable
            key={JSON.stringify({ term, key })}
            source={(page, size) => fetchUsers(page, size, { organisationId: id, vendorId: null, term: term })}
            empty={() => (
              <div className="p-4">
                {term.length != 0 ? `There are no members matching ${term}.` : 'There are no members.'}
              </div>
            )}
            renderHeader={() => (
              <React.Fragment>
                <Table.Header>Account</Table.Header>
                <Table.Header>Role</Table.Header>
                <Table.Header>Status</Table.Header>
                <Table.Header></Table.Header>
              </React.Fragment>
            )}
          >
            {(data: Me[]) => (
              <React.Fragment>
                {data.map((member) => (
                  <Table.LinkRow key={member.id}>
                    <Table.Data>
                      <Typography is="p" type="regular">
                        {member.firstName} {member.lastName}
                      </Typography>
                      <Typography is="span" type="sm">
                        {member.email}
                      </Typography>
                    </Table.Data>
                    <Table.Data>
                      <FormattedMessage id={translations.roles[member.role]} />
                    </Table.Data>
                    <Table.Data>
                      <Status active={member.active} />
                      <Typography is="p" type="sm">
                        Email: {member.emailVerified ? 'Verified' : 'Not verified'}
                      </Typography>
                    </Table.Data>
                    <Table.Data>
                      <Dropdown
                        renderMenu={({ close }) => (
                          <div
                            className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="user-menu-button"
                          >
                            <div className="px-4 py-3" role="none">
                              <p className="text-sm" role="none">
                                Signed in as
                              </p>
                            </div>
                          </div>
                        )}
                      >
                        {({ isOpen, open, close }) => (
                          <Button onClick={open}>
                            <Icon type="more" />
                          </Button>
                        )}
                      </Dropdown>
                    </Table.Data>
                  </Table.LinkRow>
                ))}
              </React.Fragment>
            )}
          </PaginatedTable>
        </React.Fragment>
      )}
    </Refresh>
  );
};

export default OrganizationMembersTab;
