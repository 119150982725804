import {
  DiningSlotAdd,
  DiningSlot,
  Me,
  Organization,
  OrganizationMemberAdd,
  Page,
  OrganizationProfileUpdate,
  AssetType
} from '@/domains';

import { authorizedApi } from '../network/authorized-api';
import { uploadImage } from './media';

export const getOrganizations = (page: number, size: number) =>
  authorizedApi.get<Page<Organization>>(`/admin/organizations`, { pageNumber: page, pageSize: size });

export const getOrganization = (id: string): Promise<Organization> => authorizedApi.get(`/admin/organizations/${id}`);

export const updateOrganization = (id: string, data: OrganizationProfileUpdate): Promise<Organization> => {
  const { image, ...rest } = data;

  return uploadImage(image, AssetType.OrganizationImage).then(({ imageUrl, thumbnailUrl }) => {
    return authorizedApi.patch(`admin/organizations/${id}`, {
      ...rest,
      imageUrl,
      thumbnailUrl
    });
  });
};

export const updateOrganizationAccess = (id: string, whitelistedDomains: string): Promise<Organization> =>
  authorizedApi.patch(`/admin/organizations/${id}`, { whitelistedDomains });

// Members

export const inviteOrganizationMember = (id: string, data: OrganizationMemberAdd) =>
  authorizedApi.post<Me>(`/admin/organizations/${id}/members`, { ...data });

// Spots

export const getOrganizationSpots = (orgId: string) =>
  authorizedApi.get<DiningSlot[]>(`/admin/organizations/${orgId}/slots`);

export const createOrganizationSpot = (orgId: string, data: DiningSlotAdd) =>
  authorizedApi.post<DiningSlot>(`/admin/organizations/${orgId}/slots`, data);

export const updateOrganizationSpot = (orgId: string, data: DiningSlot) =>
  authorizedApi.patch(`/admin/organizations/${orgId}/slots`, data);

export const deleteOrganizationSpot = (orgId: string, spotId: string) =>
  authorizedApi.delete(`/admin/organizations/${orgId}/slots/${spotId}`);
