import { stringify } from 'query-string';

import { API } from '@/config';

import { HttpRequestConfig, HttpError, HttpResponse } from './http';
import { ApiClient } from './api';

enum Header {
  Authorization = 'Authorization'
}

type PromiseCallback = () => Promise<any>;

interface AuthorizedApiCallbackOptions {
  onUnauthorized: PromiseCallback;
  onAccessTokenExpired: PromiseCallback;
}

export class AuthorizedApiClient extends ApiClient {
  private promise: Promise<any> | null = null;
  private options: AuthorizedApiCallbackOptions | null = null;
  public token: string | null = null;

  constructor(options: HttpRequestConfig) {
    super(options);

    this.client.interceptors.request.use(this.attachToken);
    this.client.interceptors.response.use(undefined, this.retryAfterRefresh);
  }

  configure(options: AuthorizedApiCallbackOptions) {
    this.options = options;
  }

  private attachToken = (config: HttpRequestConfig): HttpRequestConfig => {
    const headers = config.headers || {};

    return { ...config, headers: { ...headers, [Header.Authorization]: `Bearer ${this.token}` } };
  };

  private doesRequireRefresh = (response: HttpResponse<any>) => response.status === 401;

  private retryAfterRefresh = (error: HttpError<any>) => {
    if (error.isAxiosError && error.response && this.doesRequireRefresh(error.response)) {
      return this.refresh()
        .catch((error) => {
          if (this.options) this.options.onUnauthorized();

          throw error;
        })
        .then(() => this.client(error.config));
    }

    throw error;
  };

  private refresh(): Promise<any> {
    if (this.promise == null) {
      if (!this.options) throw new Error(`Token expiration strategy is not set.`);

      this.promise = this.options
        .onAccessTokenExpired()
        .then((data) => {
          this.promise = null;
          return data;
        })
        .catch((error) => {
          this.promise = null;
          throw error;
        });
    }

    return this.promise;
  }
}

export const authorizedApi = new AuthorizedApiClient({
  baseURL: API,
  headers: { 'Content-Type': 'application/json' },
  paramsSerializer: (params) => stringify(params, { skipNull: true })
});
