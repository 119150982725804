import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { getMeals } from '@/services/api/meals';

import Typography from '@/components/Typography';
import Icon from '@/components/Icon';
import Modal from '@/components/Modal';
import ImportMealsModal from '../Modal/ImportMealsModal';
import PaginatedTable from '@/components/PaginatedTable';
import Table from '@/components/Table';
import SearchBar from '@/components/SearchBar';
import { urls } from '@/constants';
import Status from '@/components/Status';
import Refresh from '@/components/Refresh';
import Link from '@/components/Link';
import Placeholder from '@/components/Placeholder';
import { Meal } from '@/domains';
import ImportFromEbrizaModal from '../Modal/ImportFromEbrizaModal';

const text = translations.pages.meals.list;

export interface Props {
  vendorId: string;
}

const MealsListPage: React.FC<Props> = ({ vendorId }) => {
  const [term, setTerm] = useState('');

  return (
    <Refresh>
      {({ refresh, key }) => (
        <React.Fragment>
          <div className="flex flex-col mb-8">
            <Typography is="h1" type="header">
              <FormattedMessage id={text.title} />
            </Typography>

            <span>The products you&apos;re selling are automatically synced with Ebriza.</span>
          </div>

          <SearchBar placeholder="Search meals by name or description..." onChange={setTerm} />

          <PaginatedTable
            key={JSON.stringify({ term, key })}
            className="w-full my-4 rounded-xl shadow"
            source={(page, number) => getMeals(vendorId, { page, size: number, term: term })}
            empty={() => {
              return (
                <Placeholder
                  image="cards"
                  size="sm"
                  title="No meals found"
                  description="Add meals manually or import an existing list of meals using a template."
                >
                  <Link
                    to={urls.menu.create}
                    className="mt-8 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Add meal
                  </Link>
                </Placeholder>
              );
            }}
            renderHeader={() => (
              <React.Fragment>
                <Table.Header key="name">Name</Table.Header>
                <Table.Header key="price">Price</Table.Header>
                <Table.Header key="status">Availability</Table.Header>
              </React.Fragment>
            )}
          >
            {(meals: Meal[]) => (
              <React.Fragment>
                {meals.map((meal) => (
                  <Table.LinkRow key={meal.id} hoverable url={urls.menu.detail.get(meal.id)}>
                    <Table.Data>
                      <div className="flex space-x-2">
                        <img
                          src={meal.thumbnailUrl}
                          className="object-cover w-10 h-10 bg-gray-200 rounded-lg"
                          loading="lazy"
                        />
                        <div className="flex flex-col">
                          <div className="text-sm font-medium text-gray-900">{meal.title}</div>
                          <div className="text-sm font-medium text-gray-900">{meal.description}</div>
                        </div>
                      </div>
                    </Table.Data>
                    <Table.Data>
                      {meal.price}
                      <span> RON</span>
                    </Table.Data>
                    <Table.Data>{/* <Status active={meal} /> */}</Table.Data>
                  </Table.LinkRow>
                ))}
              </React.Fragment>
            )}
          </PaginatedTable>
        </React.Fragment>
      )}
    </Refresh>
  );
};

export default MealsListPage;
