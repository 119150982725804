import { api } from '@/services/network/api';

import { Session, ResetPassword, TokenValidity } from '@/domains/session';

const CLIENT_ID = `kane-group-client`;

const mapSession: (session: any) => Session = (session) => {
  return {
    accessToken: session['access_token'],
    refreshToken: session['refresh_token'],
    userId: session['userId']
  };
};

export const signInWithEmail: (email: string, password: string) => Promise<Session> = (email, password) => {
  const authData = new URLSearchParams();

  authData.append('grant_type', 'password');
  authData.append('username', email);
  authData.append('password', password);

  return api
    .post<any>(`/auth/token`, authData, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' }
    })
    .then(mapSession);
};

export const renewToken: (refreshToken: string) => Promise<Session> = (refreshToken) => {
  const authData = new URLSearchParams();

  authData.append('grant_type', 'refresh_token');
  authData.append('refresh_token', refreshToken);

  return api
    .post<any>(`/auth/token`, authData, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' }
    })
    .then(mapSession);
};

export const sendResetPasswordLink: (email: string) => Promise<any> = (email) =>
  api.post<any>(`/users/password-token`, { email });

export const checkResetPasswordTokenValidity: (token: string) => Promise<TokenValidity> = (token) =>
  api.post<TokenValidity>(`users/password-token-validation`, { token });

export const resetPassword: (password: ResetPassword) => Promise<any> = (password) =>
  api.put<any>(`/users/password`, password);
