import React, { useState } from 'react';

import PaginatedTable from '@/components/PaginatedTable';
import Table from '@/components/Table';
import Typography from '@/components/Typography';
import { urls } from '@/constants';
import { fetchUsers } from '@/services/api/users';
import SearchBar from '@/components/SearchBar';

const UsersListPage: React.FC = () => {
  const [term, setTerm] = useState('');

  return (
    <React.Fragment>
      <div className="flex">
        <Typography is="h1" type="header">
          Users
        </Typography>
      </div>

      <SearchBar onChange={setTerm} placeholder={'Filter by name or email ...'} showFilter={false} className="py-4" />

      <PaginatedTable
        key={term}
        className="w-full py-4"
        source={(page, number) => fetchUsers(page, number, { organisationId: null, vendorId: null, term: term })}
        empty={() => <div>Nothing to show</div>}
        renderHeader={() => (
          <React.Fragment>
            <Table.Header>Name</Table.Header>
          </React.Fragment>
        )}
      >
        {(data) => (
          <React.Fragment>
            {data.map((user: any) => (
              <Table.LinkRow key={user.id} hoverable url={urls.users.detail.get(user.id)}>
                <Table.Data>
                  <div className="flex space-x-4 align-middle">
                    <div>
                      {user.firstName} {user.lastName}
                    </div>
                  </div>
                </Table.Data>
              </Table.LinkRow>
            ))}
          </React.Fragment>
        )}
      </PaginatedTable>
    </React.Fragment>
  );
};

export default UsersListPage;
