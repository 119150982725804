import {
  format,
  parse,
  eachDayOfInterval,
  addDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  getDay,
  parseISO,
  differenceInYears,
  isEqual,
  isToday,
  isSameDay
} from 'date-fns';

export const getWeekdayLabels = (firstDayOfWeek = 1, weekDayFormat = 'EEE'): string[] => {
  const now = new Date();
  const arr = eachDayOfInterval({
    start: addDays(startOfWeek(now), firstDayOfWeek),
    end: addDays(endOfWeek(now), firstDayOfWeek)
  });
  return arr.reduce((array: string[], date) => {
    // @ts-ignore
    array.push(format(date, weekDayFormat));
    return array;
  }, []);
};

export const getDays = ({
  year,
  month,
  firstDayOfWeek = 1,
  dayNumberFormat = 'd'
}): (number | { date: Date; dayNumber: string })[] => {
  const date = new Date(year, month);

  const monthStart = startOfMonth(date);
  const monthStartDay = getDay(monthStart);
  const monthEnd = endOfMonth(date);

  const prevMonthDays = Array.from(
    Array(
      monthStartDay >= firstDayOfWeek ? monthStartDay - firstDayOfWeek : 6 - firstDayOfWeek + monthStartDay + 1
    ).keys()
  ).fill(0);
  const days = eachDayOfInterval({ start: monthStart, end: monthEnd }).map((date) => ({
    date,
    dayNumber: format(date, dayNumberFormat)
  }));

  return [...prevMonthDays, ...days];
};

export const getTime = (isoString: string) => format(new Date(isoString), 'HH:mm');

export const getDateRangeToString = (date1: Date, date2: Date) => {
  if (isEqual(date1, date2)) {
    if (isToday(date1)) {
      return 'Today';
    }
    return format(date1, rangeDateFormat);
  }
  return `${format(date1, rangeDateFormat)} - ${format(date2, rangeDateFormat)}`;
};

export const getFormatterMenuRange = (date1?: string, date2?: string) => {
  const startDate = date1 ? parseISO(date1) : null;
  const endDate = date2 ? parseISO(date2) : null;
  if (!startDate && !endDate) {
    return 'No date range limit';
  } else if (startDate && endDate && isSameDay(startDate, endDate)) {
    return format(startDate, rangeDateFormat);
  }
  return `${startDate ? format(startDate, rangeDateFormat) : 'No start date'} - ${
    endDate ? format(endDate, rangeDateFormat) : 'No end date'
  }`;
};

export const getDateFormat = (isoString: string, dateFormat = 'MMM d, yyyy, HH:mm') =>
  format(new Date(isoString), dateFormat);

export const getISOString = (date: string, dateFormat: string) => parse(date, dateFormat, new Date()).toISOString();

export const getNow = (dateFormat: string) => format(new Date(), dateFormat);

export const getAgeFromBirthdate: (birthDate: string) => number = (birthDate) => {
  return differenceInYears(new Date(), parseISO(birthDate));
};

export const altDateFormat = 'dd-MM-yyyy';
const rangeDateFormat = 'dd MMM. yyyy';
