import React from 'react';
import classnames from 'classnames';

import Row from '../Row';

export interface Props {
  is?: React.ElementType;
  className?: string;
}

const Header: React.FC<Props> = ({ is: Component = 'th', className, children, ...rest }) => (
  <Component
    className={classnames('px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider', className)}
    {...rest}
  >
    {children}
  </Component>
);

export default Header;
