import React from 'react';
import * as yup from 'yup';

import { getOrganization, updateOrganization } from '@/services/api/organizations';

import If from '@/components/If';
import LoadData from '@/components/LoadData';
import { OrganizationProfileUpdate } from '@/domains';
import { imageSchema } from '@/validation';
import Loading from '@/components/Loading';
import Placeholder from '@/components/Placeholder';
import { ImageInputWithValidation } from '@/components/Input/ImageInput';
import Form from '@/components/Form';
import { TextInputWithValidation } from '@/components/Input/TextInput';
import Button from '@/components/Button';
import { SubmitError } from '@/components/Error';

const schema: yup.ObjectSchema<OrganizationProfileUpdate> = yup
  .object({
    image: imageSchema.label('Cover image').required(),
    name: yup.string().label('Organization Name').required(),
    description: yup.string().label('Description').required(),
    address: yup.string().label('Address').required()
  })
  .required();

export interface Props {
  id: string;
}

const OrganizationGeneralTab: React.FC<Props> = ({ id }) => {
  return (
    <React.Fragment>
      <LoadData id={id} load={getOrganization}>
        {({ value: organization, loading }) => (
          <If
            condition={loading}
            then={() => (
              <Loading visible={loading} center className="h-full">
                <Loading.Indicator size={60} borderWidth={4} />
              </Loading>
            )}
            else={() => (
              <If
                condition={!organization}
                then={() => (
                  <Placeholder
                    className="h-full justify-center"
                    image="bell"
                    size="xs"
                    title="Oups"
                    description="Organization not found. Please try again later."
                  />
                )}
                else={() => (
                  <Form
                    id="edit-vendor-profile"
                    schema={schema}
                    initialValues={{
                      name: organization.name,
                      description: organization.description,
                      address: organization.address,
                      image: { url: organization.imageUrl, thumbnailUrl: organization.thumbnailUrl }
                    }}
                    subscription={{ submitError: true, submitting: true, dirty: true, pristine: true }}
                    onSubmit={(data) => updateOrganization(id, data)}
                    className="space-y-4"
                  >
                    {({ submitting, submitError, form }, id) => (
                      <React.Fragment>
                        <Form.Field
                          is={ImageInputWithValidation}
                          onImageUploaded={(image) => {
                            form.change('image', image);
                          }}
                          id={`${id}-image`}
                          required={true}
                          name="image"
                          label="Image"
                          readOnly={submitting}
                        />

                        <Form.Field
                          is={TextInputWithValidation}
                          id={`${id}-name`}
                          required={true}
                          name="name"
                          type="text"
                          label="Name"
                          placeholder="Enter organization name"
                          readOnly={submitting}
                        />

                        <Form.Field
                          is={TextInputWithValidation}
                          id={`${id}-description`}
                          inputIs="textarea"
                          rows={3}
                          required={true}
                          name="description"
                          type="text"
                          label="Description"
                          placeholder="Business description"
                          readOnly={submitting}
                        />

                        <Form.Field
                          is={TextInputWithValidation}
                          id={`${id}-address`}
                          required={true}
                          name="address"
                          type="text"
                          label="Address"
                          placeholder="Set business address"
                          readOnly={submitting}
                        />

                        <SubmitError error={submitError} />

                        <Button type="submit" loading={submitting} appearance="ghost">
                          Save
                        </Button>
                      </React.Fragment>
                    )}
                  </Form>
                )}
              />
            )}
          />
        )}
      </LoadData>
    </React.Fragment>
  );
};

export default OrganizationGeneralTab;
