export enum OrderStatus {
  PLACED,
  ARRIVED,
  FINALIZED,
  CANCELLED
}

export class OrderProductDto {
  id: string;
  title: string;
  count: number;
}

export interface OrderUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}
export interface Order {
  id: string;
  pin: string;
  status: OrderStatus;
  totalPrice: number;
  items: OrderProductDto[];
  pickupDate: Date;
  createdAt: Date;
  user: OrderUser;
}
