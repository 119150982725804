import React from 'react';
import { parse, stringify } from 'query-string';
import * as yup from 'yup';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { translations } from '@/locale';
import { signInWithEmail } from '@/services/api/session';
import { Form } from 'react-final-form';
import Button from '@/components/Button';

import logo from '@/assets/logo.svg';

const schema: yup.ObjectSchema = yup.object({
  email: yup.string().email().label(translations.inputs.email.label).required()
});

export interface Props extends RouteComponentProps {}

const ForgotPasswordPage: React.FC<Props & WrappedComponentProps> = (props) => {
  const email = parse(location.search).email as string;

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={logo} alt="Logo" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Forgot password?</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Form
            id="sign-in"
            className="space-y-6"
            schema={schema}
            initialValues={{ email }}
            subscription={{ submitError: true, submitting: true, values: true }}
            onSubmit={(values) => signInWithEmail(values.email, values.password)}
          >
            {({ submitError, submitting, values }, id) => (
              <div className="space-y-6">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <Button type="submit" className="w-full" loading={submitting} appearance="orange">
                  Reset password
                </Button>
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ForgotPasswordPage);
