import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { urls } from '@/constants';

import OrganizationsListPage from './OrganizationsListPage';
import OrganizationDetailPage from './OrganizationDetailPage';
import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';

const Organizations: React.FC = () => (
  <Switch>
    <Route exact path={urls.organizations.list} component={OrganizationsListPage} />
    <Route path={urls.organizations.detail.main} component={OrganizationDetailPage} />
    <ConsumeRedirectRoute render={() => <Redirect to={urls.organizations.list} />} />
  </Switch>
);

export default Organizations;
