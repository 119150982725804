import { generatePath } from 'react-router';

export const signIn = `/login`;
export const signUp = {
  main: `/signup`,
  sent: `/signup/sent`
};

export const acceptInvitation = `/accept-invitation`;

export const forgotPassword = {
  main: `/forgot-password`,
  sent: `/forgot-password/sent`
};

export const resetPassword = {
  main: `/reset-password`,
  expired: `/reset-password/expired`
};

export const home = `/`;

export const dashboard = `/dashboard`;

export const users = {
  list: `/users`,
  detail: {
    main: `/users/:id`,
    get: (id: string) => generatePath(`/users/:id`, { id })
  }
};

export const orders = {
  list: `/orders`,
  detail: {
    main: `/orders/:id`,
    get: (id: string) => generatePath(`/orders/:id`, { id })
  }
};

export const menu = {
  list: `/meals`,
  create: `/meals/new`,
  detail: {
    main: `/meals/:id`,
    get: (id: string) => generatePath(`/meals/:id`, { id })
  }
};

export const vendors = {
  list: `/vendors`,
  detail: {
    main: `/vendors/:id`,
    get: (id: string) => generatePath(`/vendors/:id`, { id })
  },
  availability: {
    main: `/vendors/:id/availability`,
    get: (id: string) => generatePath(`/vendors/:id/availability`, { id })
  },
  members: {
    main: `/vendors/:id/members`,
    get: (id: string) => generatePath(`/vendors/:id/members`, { id })
  },
  meals: {
    main: `/vendors/:id/meals`,
    get: (id: string) => generatePath(`/vendors/:id/meals`, { id })
  }
};

export const settings = {
  general: {
    main: '/settings',
    get: (id: string) => '/settings'
  },
  availability: {
    main: '/settings/availability',
    get: (id: string) => '/settings/availability'
  },
  members: {
    main: '/settings/members',
    get: (id: string) => '/settings/members'
  }
};

export const platform = {
  general: {
    main: '/platform',
    get: (id: string) => '/platform'
  }
};

export const organizations = {
  list: `/organizations`,
  detail: {
    main: `/organizations/:id`,
    get: (id: string) => generatePath(`/organizations/:id`, { id })
  },
  vendors: {
    main: `/organizations/:id/vendors`,
    get: (id: string) => generatePath(`/organizations/:id/vendors`, { id })
  },
  diningSlots: {
    main: `/organizations/:id/dining-slots`,
    get: (id: string) => generatePath(`/organizations/:id/dining-slots`, { id })
  },
  members: {
    main: `/organizations/:id/members`,
    get: (id: string) => generatePath(`/organizations/:id/members`, { id })
  },
  access: {
    main: `/organizations/:id/access`,
    get: (id: string) => generatePath(`/organizations/:id/access`, { id })
  }
};
