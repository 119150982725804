import React, { useState } from 'react';
import { parse } from 'query-string';
import * as yup from 'yup';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import Form from '@/components/Form';
import Button from '@/components/Button';

import { PasswordInputWithValidation } from '@/components/Input/PasswordInput';
import { resetPassword } from '@/services/api/me';
import { SubmitError } from '@/components/Error';
import If from '@/components/If';

const schema: yup.ObjectSchema<{ password: string; passwordConfirmation: string }> = yup.object({
  password: yup.string().min(8).label('Parolă nouă'),
  passwordConfirmation: yup.string().min(8).label('Confirmă parola')
});

export interface Props extends RouteComponentProps {}

const ResetPasswordPage: React.FC<Props & WrappedComponentProps> = (props) => {
  const token = parse(props.location.search).token as string;
  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src="https://d2u3ugb6alqa7d.cloudfront.net/product/logo.png" alt="Logo" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Resetează parola</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <If
            condition={showConfirmation}
            then={() => (
              <div className="rounded-md bg-green-50 p-4 mb-4">
                <div className="ml-2">
                  <div className="text-sm text-green-700">
                    <p>Parola dumneavoastră a fost resetată cu succes</p>
                  </div>
                </div>
              </div>
            )}
          />

          <Form
            id="sign-in"
            className="space-y-6"
            schema={schema}
            initialValues={{ password: '', passwordConfirmation: '' }}
            subscription={{ submitError: true, submitting: true, values: true, hasValidationErrors: true }}
            onSubmit={(values) =>
              resetPassword(values.password, token).then(() => {
                setShowConfirmation(true);
              })
            }
          >
            {({ submitError, submitting, values, hasValidationErrors }, id) => (
              <div className="space-y-6">
                <Form.Field
                  is={PasswordInputWithValidation}
                  id={`${id}-password`}
                  name="password"
                  type="text"
                  label="Parolă nouă"
                  placeholder="Parolă nouă"
                  required={true}
                  readOnly={submitting}
                />

                <Form.Field
                  is={PasswordInputWithValidation}
                  id={`${id}-password-confirmation`}
                  name="passwordConfirmation"
                  type="text"
                  label="Confirmă parola"
                  placeholder="Confirmă parola"
                  required={true}
                  readOnly={submitting}
                />

                {submitError && <SubmitError error={submitError} />}

                <Button
                  type="submit"
                  className="w-full"
                  loading={submitting}
                  disabled={hasValidationErrors || values.password != values.passwordConfirmation}
                  appearance="orange"
                >
                  Resetează parola
                </Button>
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ResetPasswordPage);
