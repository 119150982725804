import React from 'react';

import usePagination, { Options as UsePaginationOptions } from '@/hooks/usePagination';
import Loading from '../Loading';
import Table from '../Table';
import If from '../If';
import Button from '../Button';
import classNames from 'classnames';

export interface Props<K, T> extends Pick<UsePaginationOptions<K, T>, 'source'> {
  renderHeader: () => any | null;
  empty: () => any;
  children: (data: T[]) => React.ReactElement;
  className?: string;
}

const PaginatedTable = <K, T>({ ...props }: React.PropsWithChildren<Props<K, T>>) => {
  const { renderHeader, empty, children, className } = props;
  const { data, page, loading, totalNumberOfElements, loadPage } = usePagination<T, K>(props);

  return (
    <div className={classNames('flex flex-col', className)}>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="border-b border-solid border-gray-200 sm:rounded-lg">
            <Table className="w-full divide-gray-200">
              <If
                condition={renderHeader}
                then={() => (
                  <thead className="bg-gray-50">
                    <tr>{renderHeader()}</tr>
                  </thead>
                )}
              />

              <Table.Body>
                {children(data)}
                <Loading visible={loading} center>
                  <Loading.Indicator size={20} borderWidth={2} />
                </Loading>
              </Table.Body>
            </Table>

            <If condition={!loading && data.length === 0} then={empty} />

            <If
              condition={data && data.length > 0}
              then={() => (
                <nav
                  className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 rounded-b-xl"
                  aria-label="Pagination"
                >
                  <p className="text-sm text-gray-700">{`Showing ${page} to ${page} of ${totalNumberOfElements} results`}</p>
                  <div className="flex-1 flex justify-end">
                    <Button
                      className="ml-3 relative inline-flex items-center px-4 py-2 border border-solid border-gray-200  text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      onClick={() => loadPage(page - 1)}
                    >
                      Previous
                    </Button>

                    <Button
                      className="ml-3 relative inline-flex items-center px-4 py-2 border border-solid border-gray-200 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      onClick={() => loadPage(page + 1)}
                    >
                      Next
                    </Button>
                  </div>
                </nav>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaginatedTable;
