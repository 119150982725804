import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import TabbedContainer from '@/components/TabbedContainer/TabbedContainer';
import Tab from '@/components/TabbedContainer/Tab';
import { urls } from '@/constants';

import { translations } from '@/locale';
import Typography from '@/components/Typography';
import VendorGeneralTab from '@/page/Vendors/VendorDetailPage/Tabs/VendorGeneralTab';
import VendorMembersTab from '@/page/Vendors/VendorDetailPage/Tabs/VendorMembersTab';
import VendorAvailabilityTab from '@/page/Vendors/VendorDetailPage/Tabs/VendorAvailabilityTab';
import { SessionConsumer } from '@/components/Context/Session';

const text = translations.pages.vendors;

const VendorSettingsPage: React.FC<{ id: string } & WrappedComponentProps> = ({ intl }) => {
  return (
    <SessionConsumer>
      {({ me }) => (
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="flex">
            <Typography is="h1" type="header" className="flex-1">
              Settings
            </Typography>
          </div>
          <div className="py-4">
            <TabbedContainer resourceId={me.vendor.id}>
              <Tab title={intl.formatMessage({ id: text.tabs.general.title })} to={urls.settings.general}>
                <VendorGeneralTab id={me.vendor.id} />
              </Tab>

              <Tab title={intl.formatMessage({ id: text.tabs.availability.title })} to={urls.settings.availability}>
                <VendorAvailabilityTab id={me.vendor.id} />
              </Tab>

              <Tab title={intl.formatMessage({ id: text.tabs.members.title })} to={urls.settings.members}>
                <VendorMembersTab id={me.vendor.id} />
              </Tab>
            </TabbedContainer>
          </div>
        </div>
      )}
    </SessionConsumer>
  );
};

export default injectIntl(VendorSettingsPage);
