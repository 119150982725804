import cuid from 'cuid';

import { API, RELEASE } from '@/config';

import { HttpClient, HttpRequestConfig, HttpError } from './http';

enum Header {
  ApplicationType = 'x-app-type',
  Platform = 'x-app-platform',
  Timezone = 'x-app-time-zone',
  Version = 'x-app-version',
  TraceId = 'trace-id',
  SpanId = 'span-id'
}

const stripPrerelease = (release: string) => release.split('-')[0];

export class ApiClient extends HttpClient {
  constructor(options: HttpRequestConfig) {
    super(options);

    this.client.interceptors.request.use(this.setHeaders);
  }

  private setHeaders = (config: HttpRequestConfig): HttpRequestConfig => {
    const headers = config.headers || {};
    const traceId = headers[Header.TraceId] || `t-${cuid()}`;

    return {
      ...config,
      headers: {
        ...headers,
        [Header.TraceId]: traceId,
        [Header.SpanId]: traceId,
        [Header.Timezone]: Intl.DateTimeFormat().resolvedOptions().timeZone,
        [Header.Platform]: 'Web',
        [Header.Version]: stripPrerelease(RELEASE),
        [Header.ApplicationType]: 'food-hall'
      }
    };
  };

  protected getReportOptions(error: HttpError<any>) {
    return {
      ...super.getReportOptions(error),
      traceId: error.config.headers[Header.TraceId]
    };
  }
}

export const api = new ApiClient({
  baseURL: API,
  headers: { 'Content-Type': 'application/json' }
});
