import React from 'react';
import classnames from 'classnames';

import style from './Typography.sass';

type Type = 'header' | 'subheader' | 'small-header' | 'regular' | 'sm' | 'xs';

export type Color = 'orange' | 'blue' | 'red' | 'gray' | 'white' | 'green';
type WeightType = 'lighter' | 'regular' | 'bold' | 'bolder';

const fontSize: Record<Type, string> = {
  header: 'text-3xl',
  subheader: 'text-2xl',
  'small-header': 'text-lg',
  regular: 'text-base',
  sm: 'text-sm',
  xs: 'text-xs'
};

export interface Props<T> {
  is: React.ElementType<T>;
  type?: Type;
  color?: Color;
  weight?: WeightType;
  className?: string;
  [x: string]: any;
}

export default class Typography<T> extends React.PureComponent<Props<T>> {
  render() {
    const { is: Component, type = 'regular', color, weight, className, ...rest } = this.props;

    return (
      // @ts-ignore
      <Component
        {...rest}
        className={classnames(fontSize[type], !!color && style[color], !!weight && style[weight], className)}
      />
    );
  }
}
