import React from 'react';
import * as yup from 'yup';

import { getVendor, updateVendorProfile } from '@/services/api/vendors';
import { translations } from '@/locale';
import LoadData from '@/components/LoadData';
import If from '@/components/If';
import Loading from '@/components/Loading';
import Placeholder from '@/components/Placeholder';
import Form from '@/components/Form';
import { imageSchema } from '@/validation';
import { VendorProfileUpdate } from '@/domains';
import { TextInputWithValidation } from '@/components/Input/TextInput';
import Button from '@/components/Button';
import { ImageInputWithValidation } from '@/components/Input/ImageInput';
import { SubmitError } from '@/components/Error';
import ToggleSwitch from '@/components/ToggleSwitch';

const schema: yup.ObjectSchema<VendorProfileUpdate> = yup
  .object({
    active: yup.boolean().label('Status').required(),
    image: imageSchema.label(translations.inputs.image.label).required(),
    name: yup.string().label('Name').required(),
    description: yup.string().label('Description').required(),
    address: yup.string().label('Address').required(),
    dailyOrderLimit: yup.number().label('Daily order limit'),
    orderReportEmails: yup.string().label('Scheduled reports'),
    ebrizaCategory: yup.string().label('Ebriza Category').nullable()
  })
  .required();

export interface Props {
  id: string;
}

const VendorGeneralTab: React.FC<Props> = ({ id }) => {
  return (
    <React.Fragment>
      <LoadData id={id} load={getVendor}>
        {({ value: vendor, loading }) => (
          <If
            condition={loading}
            then={() => (
              <Loading visible={loading} center className="h-full">
                <Loading.Indicator size={60} borderWidth={4} />
              </Loading>
            )}
            else={() => (
              <If
                condition={!vendor}
                then={() => (
                  <Placeholder
                    className="h-full justify-center"
                    image="bell"
                    size="xs"
                    title="Oups"
                    description="Vendor not found. Please try again later."
                  />
                )}
                else={() => (
                  <Form
                    id="edit-vendor-profile"
                    schema={schema}
                    initialValues={{
                      active: vendor.active,
                      name: vendor.name,
                      description: vendor.description,
                      address: vendor.address,
                      image: { url: vendor.imageUrl, thumbnailUrl: vendor.thumbnailUrl },
                      dailyOrderLimit: vendor.dailyOrderLimit,
                      orderReportEmails: vendor.orderReportEmails,
                      ebrizaCategory: vendor.ebrizaCategory
                    }}
                    subscription={{ submitError: true, submitting: true, dirty: true, values: true, pristine: true }}
                    onSubmit={(values) => updateVendorProfile(id, values)}
                    className="space-y-4"
                  >
                    {({ submitting, submitError, values, form }, id) => (
                      <React.Fragment>
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">Informations</h3>
                        </div>

                        <Form.Field
                          is={ImageInputWithValidation}
                          onImageUploaded={(image) => {
                            form.change('image', image);
                          }}
                          id={`${id}-image`}
                          required={true}
                          name="image"
                          label="Image"
                          readOnly={submitting}
                        />

                        <Form.Field
                          is={TextInputWithValidation}
                          id={`${id}-name`}
                          required={true}
                          name="name"
                          type="text"
                          label="Name"
                          placeholder="Enter vendor name"
                          readOnly={submitting}
                        />

                        <Form.Field
                          is={TextInputWithValidation}
                          id={`${id}-description`}
                          inputIs="textarea"
                          rows={3}
                          required={true}
                          name="description"
                          type="text"
                          label="Description"
                          placeholder="Enter a short description about the restaurant"
                          readOnly={submitting}
                        />

                        <Form.Field
                          is={TextInputWithValidation}
                          id={`${id}-address`}
                          required={true}
                          name="address"
                          type="text"
                          label="Address"
                          placeholder="Enter restaurant address"
                          readOnly={submitting}
                        />

                        <div className="pt-4">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">Status</h3>
                          <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            Disabling this vendor will prevent them from being displayed in the application.
                          </p>
                        </div>

                        <ToggleSwitch
                          id={`${id}-active`}
                          checked={values.active}
                          onChange={(e) => null}
                          readOnly={submitting}
                          onClick={(e: any) => {
                            form.change('active', !e.target.checked);
                          }}
                        />

                        <div className="pt-4">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">Daily order limit</h3>
                          <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            Limit the number of orders this vendor can fullfill in a day.
                          </p>
                        </div>

                        <Form.Field
                          is={TextInputWithValidation}
                          id={`${id}-dailyLimit`}
                          name="dailyOrderLimit"
                          icon="dashboard"
                          type="number"
                          placeholder="Enter the daily order limit"
                          readOnly={submitting}
                        />

                        <div className="pt-4">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">Scheduled reports</h3>
                          <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            Add a list of semicolon separated emails where you want to send order reports. <br />
                            The platform will send reports at 18:00 Monday through Thursday and Sunday for the next day.
                          </p>
                        </div>

                        <Form.Field
                          is={TextInputWithValidation}
                          id={`${id}-orderReportEmails`}
                          name="orderReportEmails"
                          type="text"
                          placeholder="ex.: foo@bar.com; bar@foo.com"
                          readOnly={submitting}
                        />

                        <div className="pt-4">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">Ebriza</h3>
                          <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            Enter Category Name to enable automatic product sync with Ebrirza.
                          </p>
                        </div>

                        <Form.Field
                          is={TextInputWithValidation}
                          id={`${id}-ebrizaCategory`}
                          name="ebrizaCategory"
                          type="text"
                          label="Ebriza Category"
                          placeholder="Ebriza category name: Nomenclator > AFIȘARE PRODUSE > APLICATIE"
                          readOnly={submitting}
                        />

                        <SubmitError error={submitError} />

                        <Button type="submit" loading={submitting} appearance="ghost">
                          Save
                        </Button>
                      </React.Fragment>
                    )}
                  </Form>
                )}
              />
            )}
          />
        )}
      </LoadData>
    </React.Fragment>
  );
};

export default VendorGeneralTab;
