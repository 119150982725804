import React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import classnames from 'classnames';

import Typography from '@/components/Typography';

import style from './ValidationMessage.sass';

export interface ValidationError {
  id: string;
  values: any;
}

export interface Props extends ValidationError {
  visible: boolean;
  className?: string;
}

const valueMapper: Record<string, Function> = {
  // 'validation.basic.date.min': (values) => ({ ...values, value: 'value', min: 'min' }),
  // 'validation.basic.date.max': (values) => ({ ...values, value: 'value', max: 'max' })
};

class ValidationMessage extends React.PureComponent<Props & WrappedComponentProps> {
  render() {
    const { intl, visible, id, values, className, ...rest } = this.props;

    const formattedValues = values ? { ...values, path: intl.formatMessage({ id: values.path }).toLowerCase() } : {};

    return (
      <Typography
        is="div"
        type="regular"
        className={classnames(style.root, { [style.visible]: visible }, className)}
        role="alert"
        {...rest}
      >
        {!!id && (
          <FormattedMessage id={id} values={valueMapper[id] ? valueMapper[id](formattedValues) : formattedValues} />
        )}
      </Typography>
    );
  }
}

export default injectIntl(ValidationMessage);
