import { AssetType, Meal, MealAdd, MealListItem, MealUpdate, Page } from '@/domains';
import { uploadImage } from './media';

import { authorizedApi } from '@/services/network/authorized-api';

export const getMeals = (vendorId: string, data: { page: number; size: number; term: string }) =>
  authorizedApi.get<Page<MealListItem>>(`/admin/meals?vendorId=${vendorId}`, {
    pageNumber: data.page,
    pageSize: data.size,
    term: data.term
  });

export const getMeal = (mealId: string) => authorizedApi.get<Meal>(`/admin/meals/${mealId}`);

// Create

export const importMeals = (vendorId: string, file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  return authorizedApi.post(`/admin/meals/import?vendorId=${vendorId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const createMeal = (vendorId: string, data: MealAdd) => {
  const { image, ...rest } = data;

  return uploadImage(image, AssetType.MealImage).then(({ imageUrl, thumbnailUrl }) => {
    return authorizedApi.post<Meal>(`/admin/meals?vendorId=${vendorId}`, {
      ...rest,
      imageUrl,
      thumbnailUrl
    });
  });
};

export const updateMeal = (mealId: string, data: MealUpdate) => {
  const { image, ...rest } = data;

  return uploadImage(image, AssetType.MealImage).then(({ imageUrl, thumbnailUrl }) => {
    authorizedApi.patch(`/admin/meals/${mealId}`, {
      ...rest,
      imageUrl,
      thumbnailUrl
    });
  });
};
