import { Image } from './media';

export enum MealStatus {
  UNLISTED = 'UNLISTED',
  LISTED_LIMITED = 'LISTED_LIMITED',
  LISTED_UNLIMITED = 'LISTED_UNLIMITED'
}

export interface MealListItem {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
  thumbnailUrl: string;
  price: number;
  status: MealStatus;
  dailyOrderLimit?: number;
}

export interface MealCategory {
  id: string;
  title: string;
}

export interface Meal extends MealListItem {}

export interface MealAdd {
  title: string;
  description: string;
  image: Image;
  price: number;
}

export type MealUpdate = MealAdd;
