import React from 'react';
import { parse, stringify } from 'query-string';
import * as yup from 'yup';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import Link from '@/components/Link';
import { urls } from '@/constants';
import { SignIn as SignInDomain } from '@/domains';
import { translations } from '@/locale';
import Button from '@/components/Button';
import { SubmitError } from '@/components/Error';
import Form from '@/components/Form';
import { PasswordInputWithValidation } from '@/components/Input/PasswordInput';
import { TextInputWithValidation } from '@/components/Input/TextInput';
import { SessionConsumer } from '@/components/Context/Session';

import logo from '@/assets/logo.svg';

const schema: yup.ObjectSchema<SignInDomain> = yup.object({
  email: yup.string().email().label(translations.inputs.email.label).required(),
  password: yup.string().label(translations.inputs.password.label).required()
});

export interface Props extends RouteComponentProps {}

const SignInPage: React.FC<Props & WrappedComponentProps> = (props) => {
  const email = parse(location.search).email as string;

  return (
    <SessionConsumer>
      {({ signInWithEmail }) => (
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img className="mx-auto h-12 w-auto" src={logo} alt="Workflow" />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <Form
                id="sign-in"
                className="space-y-6"
                schema={schema}
                initialValues={{ email }}
                subscription={{ submitError: true, submitting: true, values: true }}
                onSubmit={(values) => signInWithEmail(values.email, values.password)}
              >
                {({ submitError, submitting, values }, id) => (
                  <div className="space-y-6">
                    <Form.Field
                      is={TextInputWithValidation}
                      id={`${id}-email`}
                      name="email"
                      type="email"
                      label={props.intl.formatMessage({ id: translations.inputs.email.label })}
                      placeholder={props.intl.formatMessage({ id: translations.inputs.email.placeholder })}
                      readOnly={submitting}
                    />

                    <Form.Field
                      is={PasswordInputWithValidation}
                      id={`${id}-password`}
                      name="password"
                      label={props.intl.formatMessage({ id: translations.inputs.password.label })}
                      placeholder={props.intl.formatMessage({ id: translations.inputs.password.placeholder })}
                      readOnly={submitting}
                      className="mt-4"
                    />

                    <div className="flex flex-row-reverse">
                      <Link
                        appearance="orange"
                        to={{
                          pathname: urls.forgotPassword.main,
                          search: stringify({ email: values.email })
                        }}
                      >
                        Forgot your password?
                      </Link>
                    </div>

                    {!!submitError && <SubmitError error={submitError} className="mb-4" />}

                    <Button type="submit" className="w-full" loading={submitting} appearance="orange">
                      Sign in
                    </Button>
                  </div>
                )}
              </Form>
            </div>
          </div>
        </div>
      )}
    </SessionConsumer>
  );
};

export default injectIntl(SignInPage);
