import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

import { fullName } from '@/util';
import { urls } from '@/constants';
import { translations } from '@/locale';

import { SessionConsumer } from '@/components/Context/Session';
import Dropdown from '@/components/Dropdown';
import Typography from '@/components/Typography';
import Card from '@/components/Card';
import UserProfileImage from '@/components/UserProfileImage/UserProfileImage';

const text = translations.header.profile;

const ProfileDropdown: React.FC<RouteComponentProps> = ({ history }) => (
  <SessionConsumer>
    {({ me, signOut }) => (
      <Dropdown
        renderMenu={({ close }) => (
          <div
            className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
          >
            <div className="px-4 py-3" role="none">
              <p className="text-sm" role="none">
                Signed in as
              </p>
              <p className="text-sm font-medium text-gray-900" role="none">
                {me.email}
              </p>
            </div>
            <button
              type="button"
              onClick={signOut}
              className="block px-4 py-2 text-sm text-gray-700"
              role="menuitem"
              id="user-menu-item-1"
            >
              <FormattedMessage id={text.signOut} />
            </button>
          </div>
        )}
        dropdownClassName="mt-2 right-0 pl-4"
      >
        {({ isOpen, open, close }) => (
          <div className="inline-flex items-center group hover:cursor-pointer" onClick={isOpen ? close : open}>
            <UserProfileImage
              url={null}
              firstName={me.firstName}
              lastName={me.lastName}
              className="w-10 h-10 rounded-full mr-2.5 group-hover:shadow-red transition"
            />
          </div>
        )}
      </Dropdown>
    )}
  </SessionConsumer>
);

export default withRouter(ProfileDropdown);
