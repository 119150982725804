import React from 'react';
import classnames from 'classnames';

import Icon from '@/components/Icon';

import style from './SelectItem.sass';

export interface Props {
  active?: boolean;
  selected?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: () => any;
}

const SelectItem: React.SFC<Props> = ({ active, selected, disabled, className, onClick, children, ...rest }) => (
  <li
    {...rest}
    className={classnames(
      style.root,
      { [style.active]: active, [style.selected]: selected, [style.disabled]: disabled },
      className
    )}
    onClick={disabled ? null : onClick}
  >
    {children}

    {/* Replace with icon */}
    <Icon type="lock" className={style.icon} />
  </li>
);

export default SelectItem;
