import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { urls } from '@/constants';

import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';
import VendorsListPage from './VendorsListPage';
import VendorDetailPage from './VendorDetailPage';

const Vendors: React.FC = () => (
  <Switch>
    <Route exact path={urls.vendors.list} component={VendorsListPage} />
    <Route path={urls.vendors.detail.main} component={VendorDetailPage} />
    <ConsumeRedirectRoute render={() => <Redirect to={urls.vendors.list} />} />
  </Switch>
);

export default Vendors;
