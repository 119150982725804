import { Role, rules } from '@/constants';

const adminRules = [
  rules.ACCESS_APPLICATION,
  rules.MAIN_USERS_VIEW,
  rules.MAIN_ORDERS_VIEW,
  rules.MAIN_ORGANIZATIONS_VIEW,
  rules.MAIN_VENDORS_VIEW,
  rules.MAIN_USERS_VIEW,
  rules.MAIN_PLATFORM_VIEW
];

const vendorRules = [rules.ACCESS_APPLICATION, rules.MAIN_MEALS_VIEW, rules.MAIN_ORDERS_VIEW, rules.MAIN_SETTINGS_VIEW];

const organizationRules = [rules.ACCESS_APPLICATION, rules.MAIN_ORDERS_VIEW, rules.MAIN_SETTINGS_VIEW];

const permissionMap = {
  [Role.Admin]: adminRules,
  [Role.VendorAdmin]: vendorRules,
  [Role.OrgAdmin]: organizationRules
};

export const hasRole = (role: Role, rule: string) => {
  if (!role) {
    return false;
  }

  const permission = permissionMap[role];

  if (!permission) {
    return false;
  }

  if (permission.indexOf(rule) !== -1) {
    return true;
  }

  return false;
};
