import React from 'react';
import { FormattedMessage } from 'react-intl';

import { deleteOrganizationSpot, getOrganizationSpots } from '@/services/api/organizations';

import Button from '@/components/Button';
import If from '@/components/If';
import LoadData from '@/components/LoadData';
import Typography from '@/components/Typography';
import { translations } from '@/locale';
import Loading from '@/components/Loading';
import TimeSlotModal from '@/components/Modal/TimeSlotModal';
import Modal from '@/components/Modal';
import Placeholder from '@/components/Placeholder';
import Table from '@/components/Table';

const text = translations.pages.organizations.tabs.diningSlots;

export interface Props {
  id: string;
}

const OrganizationDiningSlotsTab: React.FC<Props> = ({ id }) => {
  return (
    <LoadData id={id} load={getOrganizationSpots}>
      {({ value: slots, loading, reload }) => {
        return (
          <React.Fragment>
            <div className="flex justify-between mb-8">
              <div>
                <Typography is="h1" type="subheader">
                  <FormattedMessage id={text.title} />
                </Typography>
                <Typography is="p" type="sm">
                  Add limits to individual dining slots to prevent overwhelmed dining areas.
                </Typography>
              </div>
              <Modal modal={TimeSlotModal} orgId={id} type="add" onSaved={reload}>
                {({ open }) => (
                  <Button appearance="ghost" onClick={open}>
                    Add slot
                  </Button>
                )}
              </Modal>
            </div>

            <Loading visible={loading}>
              <Loading.Indicator size={20} borderWidth={2} />
            </Loading>

            <If
              condition={!slots || loading}
              then={() => (
                <Placeholder
                  image="cards"
                  size="sm"
                  title="No dining slots"
                  description="Start adding time slots to your organization to enable dining reservations."
                />
              )}
              else={() => (
                <Table className="table-auto block">
                  <thead className="bg-gray-50">
                    <Table.Header>Slot</Table.Header>
                    <Table.Header>Limit</Table.Header>
                    <Table.Header></Table.Header>
                  </thead>
                  <Table.Body>
                    {slots.map((slot) => (
                      <Table.Row key={slot.id}>
                        <Table.Data>
                          {slot.startTime} - {slot.endTime}
                        </Table.Data>
                        <Table.Data>{slot.limit}</Table.Data>
                        <Table.Data className="block whitespace-nowrap">
                          <Button appearance="red" onClick={() => deleteOrganizationSpot(id, slot.id).then(reload)}>
                            Delete
                          </Button>
                        </Table.Data>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              )}
            />
          </React.Fragment>
        );
      }}
    </LoadData>
  );
};

export default OrganizationDiningSlotsTab;
