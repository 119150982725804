import React from 'react';
import classnames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Icon, { Type } from '@/components/Icon';
import Typography from '@/components/Typography';
import { SidebarConsumer } from '@/components/Context/Sidebar';

import Link from '@/components/Link';

export interface Props {
  icon: Type;
  url: string;
  className?: string;
  external?: boolean;
}

const NavigationItem: React.FC<Props & RouteComponentProps> = ({
  icon,
  url,
  className,
  external,
  children,
  location
}) => {
  const isActive = location.pathname.indexOf(url) === 0;

  return (
    <SidebarConsumer>
      {({ close }) => (
        <Link
          to={url}
          className={classnames(
            'border-solid border-b-2 border-transparent hover:border-yellow-500 text-gray-900 inline-flex items-center px-1 pt-1 text-sm font-medium ',
            className
          )}
          activeClassName="border-yellow-500 text-gray-900"
          onClick={close}
          external={external}
        >
          <Icon type={icon} />

          <Typography is="div" type="sm" className="pl-2 text-gray-900 hover:text-gray-700">
            {children}
          </Typography>

          <div
            className={classnames('transition-all group-hover:w-[4px] bg-red h-full rounded-l-sm ml-auto mr-0', {
              'w-0': !isActive,
              'w-[3px]': isActive
            })}
          />
        </Link>
      )}
    </SidebarConsumer>
  );
};

export default withRouter(NavigationItem);
