export interface Image {
  id?: string;
  url: string;
  thumbnailUrl: string;
  file?: File;
}

export enum AssetType {
  VendorImage = 'VENDOR_IMAGE',
  OrganizationImage = 'ORGANIZATION_IMAGE',
  MealImage = 'MEAL_IMAGE'
}
