import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { urls } from '@/constants';

import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';

import MealsListPage from './MealsListPage';
import MealDetailPage from './MealDetailPage';
import MealCreatePage from './MealCreatePage';
import { CategoriesProvider } from '@/components/Context/Categories';

const Meals: React.FC = () => (
  <CategoriesProvider>
    <Switch>
      <Route exact path={urls.menu.list} component={MealsListPage} />
      <Route path={urls.menu.create} component={MealCreatePage} />
      <Route path={urls.menu.detail.main} component={MealDetailPage} />
      <ConsumeRedirectRoute render={() => <Redirect to={urls.menu.list} />} />
    </Switch>
  </CategoriesProvider>
);

export default Meals;
