import React, { useState } from 'react';

import { getVendors } from '@/services/api/vendors';

import Modal from '@/components/Modal';
import AddVendorModal from '@/components/Modal/AddVendorModal';
import Typography from '@/components/Typography';
import PaginatedTable from '@/components/PaginatedTable';

import Table from '@/components/Table';
import { urls } from '@/constants';
import Status from '@/components/Status';
import { Vendor } from '@/domains';
import SearchBar from '@/components/SearchBar';
import Placeholder from '@/components/Placeholder';

const VendorsListPage: React.FC = () => {
  const [term, setTerm] = useState('');

  return (
    <React.Fragment>
      <div className="flex">
        <Typography is="h1" type="header" className="flex-1">
          Vendors
        </Typography>

        <Modal
          modal={AddVendorModal}
          onSubmit={() => {
            console.log('reload');
          }}
        >
          {({ open }) => (
            <button
              type="button"
              className="relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              onClick={open}
            >
              Add vendor
            </button>
          )}
        </Modal>
      </div>

      <SearchBar className="mt-4" onChange={setTerm} placeholder={'Search by vendor name ...'} showFilter={false} />

      <PaginatedTable
        key={term}
        className="w-full py-4"
        source={(page, number) => getVendors(page, number, term)}
        empty={() => (
          <Placeholder
            className="h-full justify-center my-4"
            image="bell"
            size="xs"
            title="No vendors found."
            description="We couldn't find any vendor with this search term."
          />
        )}
        renderHeader={() => (
          <React.Fragment>
            <Table.Header>Name</Table.Header>
            <Table.Header>Status</Table.Header>
          </React.Fragment>
        )}
      >
        {(data) => (
          <React.Fragment>
            {data.map((vendor: Vendor) => (
              <Table.LinkRow key={vendor.id} hoverable url={urls.vendors.detail.get(vendor.id)}>
                <Table.Data>
                  <div className="flex space-x-4 align-middle">
                    <img src={vendor.thumbnailUrl} className="object-cover w-10 h-10 bg-gray-200 rounded-lg" />
                    <div>
                      <Typography is="p">{vendor.name}</Typography>
                      <Typography is="span" type="sm">
                        {vendor.description}
                      </Typography>
                    </div>
                  </div>
                </Table.Data>
                <Table.Data>
                  <Status active={vendor.active} />
                </Table.Data>
              </Table.LinkRow>
            ))}
          </React.Fragment>
        )}
      </PaginatedTable>
    </React.Fragment>
  );
};

export default VendorsListPage;
