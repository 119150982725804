import Button from '@/components/Button';
import Icon from '@/components/Icon';
import If from '@/components/If';
import TextInput from '@/components/Input/TextInput';
import LoadData from '@/components/LoadData';
import Loading from '@/components/Loading';
import Table from '@/components/Table';
import Typography from '@/components/Typography';
import { Promotion } from '@/domains';
import useLoad from '@/hooks/useLoad';
import { createPromotion, deletePromotion, getPromotions, updatePromotion } from '@/services/api/promotion';
import React, { useEffect, useState } from 'react';

const Platform: React.FC = () => {
  const [promotions, setPromotions] = useState<Promotion[]>([]);

  const loadPromotions = async () => {
    const promotions = await getPromotions();
    setPromotions(promotions);
  };

  useEffect(() => {
    loadPromotions();
  }, []);

  // useEffect(() => {
  //   loadPromotions();
  // });

  return (
    <React.Fragment>
      <div className="flex mb-4">
        <Typography is="h1" type="header">
          Platform
        </Typography>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Promotions</h3>
            <Table className="divide-gray-200 shadow-sm mt-4">
              <thead className="bg-gray-50 overflow-hidden">
                <Table.Header># of days</Table.Header>
                <Table.Header>Promotion (%)</Table.Header>
                <Table.Header></Table.Header>
              </thead>
              <Table.Body>
                <React.Fragment>
                  {promotions.map((item, index) => (
                    <Table.Row key={item.id}>
                      <Table.Data>
                        <TextInput
                          id={`days-${item.id}`}
                          value={item.days}
                          onChange={(e) => {
                            const newPromotions = [...promotions];
                            newPromotions[index].days = parseInt(e.target.value);
                            setPromotions(newPromotions);
                          }}
                        />
                      </Table.Data>
                      <Table.Data>
                        <TextInput
                          id={`discount-${item.id}`}
                          value={item.discountPercentage}
                          onChange={(e) => {
                            const newPromotions = [...promotions];
                            newPromotions[index].discountPercentage = parseInt(e.target.value);
                            setPromotions(newPromotions);
                          }}
                        />
                      </Table.Data>
                      <Table.Data className="space-x-2">
                        <Button
                          onClick={(e) => {
                            if (item.id) {
                              updatePromotion(item.id, item).then(loadPromotions);
                            } else {
                              createPromotion(item).then(loadPromotions);
                            }
                          }}
                        >
                          Update
                        </Button>
                        <Button
                          onClick={() => {
                            if (item.id) {
                              deletePromotion(item.id).then(loadPromotions);
                            } else {
                              const newPromotions = [...promotions];
                              newPromotions.splice(index, 1);
                              setPromotions(newPromotions);
                            }
                          }}
                        >
                          <Icon type="close"></Icon>
                        </Button>
                      </Table.Data>
                    </Table.Row>
                  ))}
                </React.Fragment>
              </Table.Body>
            </Table>
            <Button
              appearance="ghost"
              className="mt-4"
              onClick={() => {
                setPromotions([
                  ...promotions,
                  {
                    id: undefined,
                    days: 0,
                    discountPercentage: 0
                  }
                ]);
              }}
            >
              Add new
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Platform;
