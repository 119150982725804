import React from 'react';
import classnames from 'classnames';

import Input, { Props as InputProps } from '@/components/Input';
import Icon, { Type } from '@/components/Icon';
import { withValidation } from '@/components/hoc/withValidation';

const style = {
  invalid: 'border-red transition ease-in-out',
  withTrail: 'pr-11'
};

export interface Props extends InputProps {
  invalid?: boolean;
  label?: string;
  inputIs?: string;
  leadingIcon?: Type;
  icon?: Type;
  onIconClick?: () => any;
  inputClassName?: string;
  required?: boolean;
}

const TextInput = (props: React.PropsWithChildren<Props>) => {
  const {
    invalid,
    className,
    inputClassName,
    label,
    inputIs,
    icon,
    leadingIcon,
    onIconClick,
    required = false,
    ...rest
  } = props;
  return (
    <div className={classnames('relative flex flex-col', { 'text-gray-light': rest.disabled }, className)}>
      {label && (
        <div className="text-sm font-medium text-gray-700">
          <label htmlFor={props.id} className={classnames('inline-block pb-2 text-gray-dark')}>
            {label}
          </label>

          {required && <span className="text-red ml-1">*</span>}
        </div>
      )}

      <Input
        id={props.id}
        is={inputIs}
        className={classnames(
          'relative box-border h-10 px-4 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 sm:text-sm',
          props.inputIs === 'textarea' && 'h-auto',
          {
            [style.invalid]: invalid,
            // [style.withLead]: leadingIcon,
            [style.withTrail]: icon
          },
          inputClassName,
          { 'bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500': !rest.readOnly }
        )}
        {...rest}
      />

      {icon && (
        <Icon
          type={icon}
          className={classnames(
            'h-10 absolute flex items-center hover:text-gray-darker bottom-0 text-gray-dark right-4',
            {
              [style.invalid]: invalid,
              'cursor-pointer': !!onIconClick
            }
          )}
        />
      )}
    </div>
  );
};

export default TextInput;
export const TextInputWithValidation = withValidation(TextInput);
