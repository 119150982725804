// if (!Intl.PluralRules) {
//   require('@formatjs/intl-pluralrules/polyfill');
//   require('@formatjs/intl-pluralrules/dist/locale-data/en');
//   require('@formatjs/intl-pluralrules/dist/locale-data/es');
//   require('@formatjs/intl-pluralrules/dist/locale-data/fr');
//   require('@formatjs/intl-pluralrules/dist/locale-data/nl');
// }

// if (!Intl.DateTimeFormat) {
//   require('@formatjs/intl-relativetimeformat/polyfill');
//   require('@formatjs/intl-datetimeformat/dist/locale-data/en');
//   require('@formatjs/intl-datetimeformat/dist/locale-data/es');
//   require('@formatjs/intl-datetimeformat/dist/locale-data/fr');
//   require('@formatjs/intl-datetimeformat/dist/locale-data/nl');
// }

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import { render, hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Modal from 'react-modal';

import { Language } from '@/constants';
import { storage } from '@/services';

import ErrorBoundary from '@/components/ErrorBoundary';
import { LanguageProvider } from '@/components/Language';
import { SessionProvider } from '@/components/Context/Session';

import App from './App';

const renderCallback = process.env.NODE_ENV === 'production' ? hydrate : render;

const dom: HTMLElement | null = document.getElementById('app');

const updateUserLocale: (language: Language) => void = (language) => {
  storage.setItem('locale', language);
};

const getUserLocale: () => Language = () => storage.getItem('locale') || Language.English;

Modal.setAppElement(dom);

renderCallback(
  <LanguageProvider language={getUserLocale()} onUpdate={updateUserLocale}>
    <ErrorBoundary>
      <BrowserRouter>
        <SessionProvider>
          <App />
        </SessionProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </LanguageProvider>,
  dom
);
