import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { urls } from '@/constants';

import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';
import OrdersListPage from './OrdersListPage';

const Orders: React.FC = () => (
  <Switch>
    <Route exact path={urls.orders.list} component={OrdersListPage} />
    <ConsumeRedirectRoute render={() => <Redirect to={urls.orders.list} />} />
  </Switch>
);

export default Orders;
