import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router';

import { translations } from '@/locale';

import Typography from '@/components/Typography';
import TabbedContainer from '@/components/TabbedContainer';
import Tab from '@/components/TabbedContainer/Tab';
import { urls } from '@/constants';
import OrganizationMembersTab from './Tabs/OrganizationMembersTab';
import OrganizationGeneralTab from './Tabs/OrganizationGeneralTab';
import OrganizationDiningSlotsTab from './Tabs/OrganizationDiningSlotsTab';
import OrganizationExclusiveVendorsTab from './Tabs/OrganizationExclusiveVendorsTab';
import OrganizationAccessTab from './Tabs/OrganizationAccessTab';

const text = translations.pages.organizations;

const OrganizationDetailPage: React.FC<RouteComponentProps<{ id: string }> & WrappedComponentProps> = ({
  intl,
  match
}) => {
  const orgId = match.params.id;
  return (
    <React.Fragment>
      <div className="flex">
        <Typography is="h1" type="header">
          Organization profile
        </Typography>
      </div>
      <div className="bg-white overflow-hidden shadow rounded-lg px-4 mt-6 pb-4">
        <TabbedContainer resourceId={orgId}>
          <Tab title={intl.formatMessage({ id: text.tabs.general.title })} to={urls.organizations.detail}>
            <OrganizationGeneralTab id={orgId} />
          </Tab>

          <Tab title={intl.formatMessage({ id: text.tabs.exclusiveVendors.title })} to={urls.organizations.vendors}>
            <OrganizationExclusiveVendorsTab id={orgId} />
          </Tab>

          <Tab title={intl.formatMessage({ id: text.tabs.diningSlots.title })} to={urls.organizations.diningSlots}>
            <OrganizationDiningSlotsTab id={orgId} />
          </Tab>

          <Tab title={intl.formatMessage({ id: text.tabs.members.title })} to={urls.organizations.members}>
            <OrganizationMembersTab id={orgId} />
          </Tab>

          <Tab title={intl.formatMessage({ id: text.tabs.access.title })} to={urls.organizations.access}>
            <OrganizationAccessTab id={orgId} />
          </Tab>
        </TabbedContainer>
      </div>
    </React.Fragment>
  );
};

export default injectIntl(OrganizationDetailPage);
