import React from 'react';

import { Role } from '@/constants';

import { SessionConsumer } from '@/components/Context/Session';
import If from '@/components/If';
import VendorSettingsPage from './VendorSettingsPage';
import OrganizationSettingsPage from './OrganizationSettingsPage';

const Settings: React.FC = () => (
  <SessionConsumer>
    {({ me }) => (
      <If
        condition={me.role == Role.VendorAdmin}
        then={() => <VendorSettingsPage id={me.id} />}
        else={() => <OrganizationSettingsPage />}
      />
    )}
  </SessionConsumer>
);

export default Settings;
