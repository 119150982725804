import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import * as yup from 'yup';

import { translations } from '@/locale';
import { OrganizationMemberAdd } from '@/domains';

import { InstanceProps } from '@/components/Modal';
import Card from '@/components/Card';
import ModalHeader from '@/components/Modal/ModalHeader';
import Typography from '@/components/Typography';
import Grid from '@/components/Grid';
import Form from '@/components/Form';
import { TextInputWithValidation } from '@/components/Input/TextInput';
import { SubmitError } from '@/components/Error';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import { inviteOrganizationMember } from '@/services/api/organizations';
import { Role } from '@/constants';
import { RoleSelectInputWithValidation } from '@/components/Input/RoleSelectInput';

const fields = translations.inputs;
const texts = translations.modals.addMember;

const schema: yup.ObjectSchema<OrganizationMemberAdd> = yup
  .object({
    name: yup.string().label(fields.vendorName.label).required(),
    role: yup.mixed<Role>().oneOf([Role.OrgAdmin, Role.Cashier, Role.Member]).label(fields.role.label).required(),
    email: yup.string().email().label(fields.email.label).required()
  })
  .required();

export interface Props extends InstanceProps {
  orgId: string;
  onMemberInvited: () => any;
}

const InviteOrgMemberModal: React.FC<Props & WrappedComponentProps> = ({ close, orgId, onMemberInvited, intl }) => {
  return (
    <Card className="w-[450px] p-6 py-8">
      <Grid.Row>
        <Grid.Column className="w-full">
          <ModalHeader close={close}>
            <Typography is="p" className="font-bold">
              <FormattedMessage id={texts.title} />
            </Typography>
          </ModalHeader>
          <Typography is="span" className="font-thin">
            The member will receive an email invitation that will allow them to join the organization.
          </Typography>

          <Form
            id="add-user"
            schema={schema}
            subscription={{ submitError: true, submitting: true, dirty: true, pristine: true }}
            onSubmit={(values) =>
              inviteOrganizationMember(orgId, values).then(() => {
                close();
                onMemberInvited();
              })
            }
            className="mt-8"
          >
            {({ submitError, submitting, dirty, pristine }, id) => (
              <div className="space-y-4">
                <Form.Field
                  is={TextInputWithValidation}
                  id={`${id}-name`}
                  name="name"
                  label={intl.formatMessage({ id: fields.name.label })}
                  placeholder={intl.formatMessage({ id: fields.name.placeholder })}
                  readOnly={submitting}
                />

                <Form.Field
                  is={RoleSelectInputWithValidation}
                  id={`${id}-role`}
                  name="role"
                  label={intl.formatMessage({ id: fields.role.label })}
                  placeholder={intl.formatMessage({ id: fields.role.placeholder })}
                  roles={[Role.OrgAdmin, Role.Cashier, Role.Member]}
                  readOnly={submitting}
                />

                <Form.Field
                  is={TextInputWithValidation}
                  id={`${id}-email`}
                  name="email"
                  type="email"
                  label={intl.formatMessage({ id: fields.email.label })}
                  placeholder={intl.formatMessage({ id: fields.email.placeholder })}
                  readOnly={submitting}
                />

                <SubmitError error={submitError} />

                <Button
                  type="submit"
                  loading={submitting}
                  disabled={!dirty || pristine}
                  icon="plus"
                  appearance="red"
                  className="mt-4 w-full"
                >
                  <Icon type="plus" className="text-base leading-none pr-2" />

                  <FormattedMessage id={texts.confirm} />
                </Button>
              </div>
            )}
          </Form>
        </Grid.Column>
      </Grid.Row>
    </Card>
  );
};

export default injectIntl(InviteOrgMemberModal);
