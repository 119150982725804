import { api } from '@/services/network/api';
import { authorizedApi } from '@/services/network/authorized-api';
import { signInWithEmail } from './session';
import { Page } from '@/domains';

export const signUpWithInvitation = (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  invitationToken: string
) =>
  api
    .post(`/users`, {
      firstName,
      lastName,
      email,
      password,
      invitationToken
    })
    .then((_) => signInWithEmail(email, password));

export const fetchUsers = (
  page: number,
  size: number,
  filter?: { organisationId: string | null; vendorId: string | null; term: string | null }
) =>
  authorizedApi.get<Page<any>>(`/admin/users`, {
    ...(filter?.organisationId && { organizationId: filter.organisationId }),
    ...(filter?.vendorId && { vendorId: filter.vendorId }),
    ...(filter?.term && { search: filter.term }),
    pageNumber: page,
    pageSize: size
  });

export const fetchUserDetail = (id: string) => authorizedApi.get<any>(`/admin/users/${id}`);
