import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect, Route, Switch } from 'react-router-dom';

import { rules, urls } from '@/constants';
import { translations } from '@/locale';

import AppLayout from '@/components/AppLayout';
import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';
import ErrorBoundary from '@/components/ErrorBoundary';

import Users from '../Users';
import Dashboard from '../Dashboard';
import Orders from '../Orders';
import Header from '@/components/Header';
import Meals from '../Meals';
import HasRole from '@/components/HasRole';
import Vendors from '../Vendors';
import Settings from '../Settings';
import Organizations from '../Organizations';
import Platform from '../Platform';

export interface Props {}

const HomePage: React.FC<Props> = () => (
  <AppLayout>
    <AppLayout.Header>
      <Header>
        <Header.Item icon="dashboard" url={urls.dashboard}>
          <FormattedMessage id={translations.navigation.dashboard} />
        </Header.Item>

        <HasRole to={rules.MAIN_MEALS_VIEW}>
          <Header.Item icon="meals" url={urls.menu.list}>
            <FormattedMessage id={translations.navigation.menu} />
          </Header.Item>
        </HasRole>

        <HasRole to={rules.MAIN_ORDERS_VIEW}>
          <Header.Item icon="orders" url={urls.orders.list}>
            <FormattedMessage id={translations.navigation.orders} />
          </Header.Item>
        </HasRole>

        <HasRole to={rules.MAIN_VENDORS_VIEW}>
          <Header.Item icon="users" url={urls.vendors.list}>
            <FormattedMessage id={translations.navigation.vendors} />
          </Header.Item>
        </HasRole>

        <HasRole to={rules.MAIN_ORGANIZATIONS_VIEW}>
          <Header.Item icon="organization" url={urls.organizations.list}>
            <FormattedMessage id={translations.navigation.organizations} />
          </Header.Item>
        </HasRole>

        <HasRole to={rules.MAIN_VENDORS_VIEW}>
          <Header.Item icon="users" url={urls.users.list}>
            <FormattedMessage id={translations.navigation.users} />
          </Header.Item>
        </HasRole>

        <HasRole to={rules.MAIN_SETTINGS_VIEW}>
          <Header.Item icon="settings" url={urls.settings.general.main}>
            <FormattedMessage id={translations.navigation.settings} />
          </Header.Item>
        </HasRole>

        <HasRole to={rules.MAIN_PLATFORM_VIEW}>
          <Header.Item icon="settings" url={urls.platform.general.main}>
            <FormattedMessage id={translations.navigation.platform} />
          </Header.Item>
        </HasRole>
      </Header>
    </AppLayout.Header>
    <AppLayout.Page>
      <AppLayout.Content>
        <div className="p-4 md:p-6 mx-auto max-w-7xl w-full">
          <ErrorBoundary>
            <Switch>
              <Route path={urls.dashboard} component={Dashboard} />
              <Route path={urls.users.list} component={Users} />
              <Route path={urls.orders.list} component={Orders} />
              <Route path={urls.menu.list} component={Meals} />
              <Route path={urls.vendors.list} component={Vendors} />
              <Route path={urls.organizations.list} component={Organizations} />
              <Route path={urls.settings.general.main} component={Settings} />
              <Route path={urls.platform.general.main} component={Platform} />
              <ConsumeRedirectRoute render={() => <Redirect to={urls.dashboard} />} />
            </Switch>
          </ErrorBoundary>
        </div>
      </AppLayout.Content>
    </AppLayout.Page>
  </AppLayout>
);

export default HomePage;
