import React from 'react';
import classnames from 'classnames';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';

import Link from '@/components/Link';
import Tab from './Tab';
import Typography from '@/components/Typography';

import style from './TabbedContainer.sass';

interface Props {
  resourceId: string;
  className?: string;
}

const TabbedContainer: React.FC<Props & RouteComponentProps> = ({ resourceId, className, children, location }) => {
  const renderTabItem = (child: Tab, index) => {
    const url = child.props.to.get(resourceId);
    const isTabActive = location.pathname === url;

    return (
      <Link key={index} to={url} className="group relative mx-4 py-4" activeClassName="text-black opacity-100">
        <Typography
          is="span"
          type="regular"
          className={classnames('py-4 font-medium', {
            'text-gray-400 group-hover:text-gray-500': !isTabActive,
            'border-yellow-500 text-yellow-600 border-b-2 border-solid': isTabActive
          })}
        >
          {child.props.title}
        </Typography>

        <div
          className={classnames(
            'absolute inset-x-0 mx-auto -bottom-px w-[26px] h-[1px] transition transform group-hover:scale-150 group-hover:bg-red',
            {
              'bg-red': isTabActive
            }
          )}
        />
      </Link>
    );
  };

  return (
    <div className={classnames('h-full w-full flex flex-col', className)}>
      <div className="w-full border-b border-gray-200 border-solid mb-5">
        <div className="flex flex-row items-center justify-start -mx-2 ">
          {React.Children.map(children, renderTabItem)}
        </div>
      </div>

      <Switch>
        {React.Children.map(children, (child: Tab, index) => {
          return (
            <Route
              key={index}
              exact={child.props.exact ?? true}
              path={child.props.to.main}
              component={() => child as any}
            />
          );
        })}
      </Switch>
    </div>
  );
};

export default withRouter(TabbedContainer);
