import If from '@/components/If';
import LoadData from '@/components/LoadData';
import Loading from '@/components/Loading';
import Placeholder from '@/components/Placeholder';
import { urls } from '@/constants';
import { translations } from '@/locale';
import { fetchUserDetail } from '@/services/api/users';
import { DateTimeFormat } from '@formatjs/intl-datetimeformat';
import React from 'react';
import { FormattedDate, WrappedComponentProps, injectIntl } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';

const UserDetailPage: React.FC<RouteComponentProps<{ id: string }> & WrappedComponentProps> = ({ match, intl }) => {
  const userId = match.params.id;
  return (
    <main>
      <LoadData id={userId} load={fetchUserDetail}>
        {({ value: user, loading }) => (
          <If
            condition={loading}
            then={() => (
              <Loading visible={loading} center className="h-full">
                <Loading.Indicator size={60} borderWidth={4} />
              </Loading>
            )}
            else={() => (
              <If
                condition={!user}
                then={() => (
                  <Placeholder
                    className="h-full justify-center"
                    image="bell"
                    size="xs"
                    title="Oups"
                    description="Organization not found. Please try again later."
                  />
                )}
                else={() => (
                  <React.Fragment>
                    <div className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                      <div>
                        <h1 className="text-2xl font-bold text-gray-900">
                          {user.firstName} {user.lastName}
                        </h1>
                        <p className="text-sm font-medium text-gray-500">
                          Joined on{' '}
                          <time dateTime={user.createdAt}>
                            <FormattedDate value={user.createdAt} />
                          </time>
                        </p>
                      </div>
                    </div>

                    <div className="mx-auto mt-8 grid max-w-3x gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2">
                      <div className="space-y-6">
                        <section aria-labelledby="user-information">
                          <div className="bg-white shadow rounded-lg">
                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="col-span-2">
                                  <dt className="text-sm font-medium text-gray-500">User role</dt>
                                  <dd className="mt-1 text-sm text-gray-900">
                                    {intl.formatMessage({ id: translations.roles[user.role] })}
                                  </dd>
                                </div>
                                <div className="sm:col-span-1">
                                  <dt className="text-sm font-medium text-gray-500">Organisation</dt>
                                  <If
                                    condition={user.organization}
                                    then={() => (
                                      <Link
                                        className="mt-1 text-sm font-semibold text-blue-800 hover:underline"
                                        to={urls.organizations.detail.get(user.organization.id)}
                                      >
                                        {user.organization.name}
                                      </Link>
                                    )}
                                    else={() => <dd className="mt-1 text-sm text-gray-900">-</dd>}
                                  />
                                </div>
                                <div className="sm:col-span-1">
                                  <dt className="text-sm font-medium text-gray-500">Email address</dt>
                                  <dd className="mt-1 text-sm text-gray-900">{user.email}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                  <dt className="text-sm font-medium text-gray-500">Vendor</dt>
                                  <If
                                    condition={user.vendor}
                                    then={() => (
                                      <Link
                                        className="mt-1 text-sm font-semibold text-blue-800 hover:underline"
                                        to={urls.vendors.detail.get(user.vendor.id)}
                                      >
                                        {user.vendor.name}
                                      </Link>
                                    )}
                                    else={() => <dd className="mt-1 text-sm text-gray-900">-</dd>}
                                  />
                                </div>
                                <div className="sm:col-span-1">
                                  <dt className="text-sm font-medium text-gray-500">Phone</dt>
                                  <dd className="mt-1 text-sm text-gray-900">{user.phoneNumber ?? '-'}</dd>
                                </div>
                              </dl>
                            </div>
                          </div>
                        </section>
                      </div>

                      <section aria-labelledby="past-orders">
                        <div className="bg-white px-4 py-5 shadow rounded-lg sm:px-6">
                          <h2 id="past-orders" className="text-lg font-medium text-gray-900">
                            Latest orders
                          </h2>

                          <div className="mt-6 flex flex-col">
                            {user.payments.map((payment) => (
                              <div
                                className="bg-white hover:bg-gray-50 px-3 py-2 shadow rounded-lg mb-4 "
                                key={payment.id}
                              >
                                <div className="text-md">
                                  <h3 className="font-medium text-gray-900">
                                    <b>Payment</b> #{payment.id}
                                  </h3>
                                  <p>
                                    <b>Amount</b>: {payment.price}RON - {payment.paymentStatus}
                                  </p>
                                  <p className="text-gray-500">
                                    <FormattedDate value={payment.createdAt} dateStyle="medium" timeStyle="short" />
                                  </p>
                                  <hr className="my-2" />

                                  <div className="py-2">
                                    {payment.orders.map((order) => (
                                      <div key={order.id} className="flex flex-row space-x-2 align-top">
                                        <div className="h-8 w-8 rounded-full flex items-center justify-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="black"
                                            className="w-6 h-6"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                                            />
                                          </svg>
                                        </div>
                                        <ul>
                                          <li>
                                            <b>Order id:</b> {order.id}
                                          </li>
                                          <li>
                                            <b>Status:</b> {order.status}
                                          </li>
                                          <li>
                                            <b>Items</b>:
                                            <ul className="ml-4">
                                              {order.items.map((item) => (
                                                <li key={item}>{item}</li>
                                              ))}
                                            </ul>
                                          </li>
                                          <div>
                                            <b>Push notification</b>: <span className="text-green-500">Sent</span>
                                          </div>
                                          <div>
                                            <b>Sent to KDS</b>: <span className="text-green-500">OK</span>
                                          </div>
                                        </ul>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </section>
                    </div>
                  </React.Fragment>
                )}
              />
            )}
          />
        )}
      </LoadData>
    </main>
  );
};

export default injectIntl(UserDetailPage);
