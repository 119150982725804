import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

import { translations } from '@/locale';

import Typography from '@/components/Typography';
import MealForm from '@/components/Form/MealForm';
import Button from '@/components/Button';
import { createMeal } from '@/services/api/meals';
import { MealAdd } from '@/domains';
import Form from '@/components/Form';
import { imageSchema } from '@/validation';

const text = translations.pages.meals.create;

export interface Props {
  vendorId: string;
}

const schema: yup.ObjectSchema<MealAdd> = yup
  .object({
    title: yup.string().label('title').required(),
    description: yup.string().label('description').required(),
    price: yup.number().label('price').required(),
    categoryId: yup.string().label('category').required(),
    image: imageSchema.label(translations.inputs.image.label)
  })
  .required();

const MealCreatePage: React.FC<Props> = ({ vendorId }) => {
  return (
    <Form
      id="create-meal"
      schema={schema}
      subscription={{ submitError: true, submitting: true, dirty: true, pristine: true }}
      onSubmit={(values) => createMeal(vendorId, values)}
    >
      {(formRenderProps, id) => {
        return (
          <React.Fragment>
            <div className="flex justify-between mb-8">
              <Typography is="h1" type="header">
                <FormattedMessage id={text.title} />
              </Typography>
              <Button type="submit" appearance="ghost">
                Save
              </Button>
            </div>
            <div className="bg-white overflow-hidden shadow rounded-lg p-4 mt-6">
              <MealForm id={id} formProps={formRenderProps} scope="create" />
            </div>
          </React.Fragment>
        );
      }}
    </Form>
  );
};

export default MealCreatePage;
