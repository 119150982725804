import React from 'react';
import classnames from 'classnames';

export interface Props {
  is?: React.ElementType;
  className?: string;
}

const Body: React.FC<Props> = ({ is: Component = 'tbody', className, children, ...rest }) => (
  <Component className={classnames('bg-white divide-y divide-gray-200', className)} {...rest}>
    {children}
  </Component>
);

export default Body;
