import React from 'react';
import * as yup from 'yup';

import Form from '@/components/Form';
import Typography from '@/components/Typography';
import { TextInputWithValidation } from '@/components/Input/TextInput';
import { SubmitError } from '@/components/Error';
import Button from '@/components/Button';
import LoadData from '@/components/LoadData';
import { getOrganization, updateOrganizationAccess } from '@/services/api/organizations';
import Loading from '@/components/Loading';
import If from '@/components/If';
import Placeholder from '@/components/Placeholder';

const schema: yup.ObjectSchema<{ whitelistedDomains: string }> = yup
  .object({
    whitelistedDomains: yup.string().label('Whitelist Domains').required()
  })
  .required();

export interface Props {
  id: string;
}

const OrganizationAccessTab: React.FC<Props> = ({ id }) => {
  return (
    <React.Fragment>
      <div>
        <Typography is="h1" type="subheader">
          Access
        </Typography>
        <Typography is="p" type="sm">
          Whitelist domains to allow access members to sign up into the organization without having to send out
          invitation emails.
        </Typography>
      </div>
      <LoadData id={id} load={getOrganization}>
        {({ value: organization, loading }) => (
          <If
            condition={loading}
            then={() => (
              <Loading visible={loading} center className="h-full">
                <Loading.Indicator size={60} borderWidth={4} />
              </Loading>
            )}
            else={() => (
              <If
                condition={!organization}
                then={() => (
                  <Placeholder
                    className="h-full justify-center"
                    image="bell"
                    size="xs"
                    title="Oups"
                    description="Organization not found. Please try again later."
                  />
                )}
                else={() => (
                  <Form
                    id="org-exclusive-vendors"
                    schema={schema}
                    initialValues={{
                      whitelistedDomains: organization.whitelistedDomains
                    }}
                    subscription={{ submitError: true, submitting: true, dirty: true, pristine: true }}
                    onSubmit={(values) => {
                      return updateOrganizationAccess(id, values.whitelistedDomains);
                    }}
                    className="space-y-4 pt-8"
                  >
                    {({ submitting, submitError }, id) => (
                      <React.Fragment>
                        <Form.Field
                          is={TextInputWithValidation}
                          id={`${id}-whitelistedDomains`}
                          inputIs="textarea"
                          rows={3}
                          name="whitelistedDomains"
                          type="text"
                          label="Whitelisted Domains"
                          placeholder="example.com; foo.com"
                          readOnly={submitting}
                        />

                        <SubmitError error={submitError} />

                        <Button type="submit" loading={submitting} appearance="ghost">
                          Save
                        </Button>
                      </React.Fragment>
                    )}
                  </Form>
                )}
              />
            )}
          />
        )}
      </LoadData>
    </React.Fragment>
  );
};

export default OrganizationAccessTab;
