import React from 'react';
import classnames from 'classnames';

interface Props {
  isOpen: boolean;
  className?: string;
  onClick: () => any;
}

const MenuButton = ({ className, isOpen, onClick }: Props) => (
  <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
    <button
      type="button"
      className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
      aria-controls="mobile-menu"
      aria-expanded="false"
      onClick={onClick}
    >
      <span className="sr-only">Open main menu</span>

      <svg
        className={classnames('h-6 w-6', isOpen && 'hidden', !isOpen && 'block')}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
      </svg>

      <svg
        className={classnames('h-6 w-6', isOpen && 'block', !isOpen && 'hidden')}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
  </div>
);

export default MenuButton;
