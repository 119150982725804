import React from 'react';
import classnames from 'classnames';

export interface Props {
  url?: string;
  firstName: string;
  lastName: string;
  className?: string;
}

const UserProfileImage: React.FC<Props> = ({ url, firstName, lastName, className }) => {
  const initials = !url ? `${firstName.substr(0, 1)}${lastName.substr(0, 1)}`.toUpperCase() : null;

  return (
    <div
      {...(url && { style: { backgroundImage: `url(${url})` } })}
      className={classnames(
        {
          'overflow-hidden bg-white inline-flex items-center justify-center font-bold border border-solid border-gray-lighter':
            !url,
          'inline-block bg-cover bg-no-repeat': url
        },
        className
      )}
    >
      {!url && initials}
    </div>
  );
};

export default UserProfileImage;
