import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import * as yup from 'yup';

import { translations } from '@/locale';

import { InstanceProps } from '@/components/Modal';
import Card from '@/components/Card';
import ModalHeader from '@/components/Modal/ModalHeader';
import Typography from '@/components/Typography';
import Grid from '@/components/Grid';
import { VendorAdd } from '@/domains/vendor';
import Form from '@/components/Form';
import { TextInputWithValidation } from '@/components/Input/TextInput';
import { SubmitError } from '@/components/Error';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import { inviteVendor } from '@/services/api/vendors';
import Checkbox from '@/components/Checkbox';

const fields = translations.inputs;

const schema: yup.ObjectSchema<VendorAdd> = yup
  .object({
    name: yup.string().label(fields.vendorName.label).required(),
    email: yup.string().email().label(fields.email.label)
  })
  .required();

export interface Props extends InstanceProps {
  onSubmit: () => void;
}

const AddVendorModal: React.FC<Props & WrappedComponentProps> = ({ close, onSubmit, intl }) => {
  return (
    <Card className="p-6 max-w-xl">
      <Grid.Row>
        <Grid.Column className="w-full">
          <ModalHeader close={close}>
            <Typography is="span" className="font-bold">
              <FormattedMessage id={translations.modals.addVendor.title} />
            </Typography>
          </ModalHeader>

          <Form
            id="add-user"
            schema={schema}
            subscription={{ submitError: true, submitting: true, dirty: true, pristine: true }}
            onSubmit={(values) =>
              inviteVendor(values).then(() => {
                onSubmit();
                close();
              })
            }
            className="mt-8 space-y-4"
          >
            {({ submitError, submitting, dirty, pristine }, id) => (
              <React.Fragment>
                <Form.Field
                  required={true}
                  is={TextInputWithValidation}
                  id={`${id}-name`}
                  name="name"
                  type="text"
                  label={intl.formatMessage({ id: fields.vendorName.label })}
                  placeholder={intl.formatMessage({ id: fields.vendorName.placeholder })}
                  readOnly={submitting}
                />

                <Form.Field
                  is={TextInputWithValidation}
                  id={`${id}-email`}
                  name="email"
                  type="email"
                  label={intl.formatMessage({ id: fields.email.label })}
                  placeholder={intl.formatMessage({ id: fields.email.placeholder })}
                  readOnly={submitting}
                />
                <Typography is="p" type="sm">
                  Email is not mandatory. If you enter a valid email address, the person will automatically get an
                  invitation to join as a Vendor Admin.
                </Typography>

                <SubmitError error={submitError} />

                <Button
                  type="submit"
                  loading={submitting}
                  disabled={!dirty || pristine}
                  appearance="red"
                  className="mt-4 w-full"
                >
                  <Icon type="plus" className="text-base leading-none pr-2" />

                  <FormattedMessage id={translations.modals.addVendor.confirm} />
                </Button>
              </React.Fragment>
            )}
          </Form>
        </Grid.Column>
      </Grid.Row>
    </Card>
  );
};

export default injectIntl(AddVendorModal);
