import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import classnames from 'classnames';

import style from './Link.sass';

export type Appearance = 'orange' | 'blue' | 'red';

export interface Props extends NavLinkProps {
  appearance?: Appearance;
  className?: string;
  underlined?: boolean;
  external?: boolean;
}

const Link: React.FC<Props> = ({ appearance, className, underlined, external, children, ...rest }) => (
  <React.Fragment>
    {external ? (
      <a
        className={classnames(style.root, { [style.underlined]: underlined }, style[appearance], className)}
        target="_blank"
        rel="noopener noreferrer"
        href={rest.to.toString()}
      >
        {children}
      </a>
    ) : (
      <NavLink
        className={classnames(style.root, { [style.underlined]: underlined }, style[appearance], className)}
        {...rest}
      >
        {children}
      </NavLink>
    )}
  </React.Fragment>
);

export default Link;
