import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import * as yup from 'yup';

import { translations } from '@/locale';

import { DiningSlotAdd } from '@/domains';

import { InstanceProps } from '@/components/Modal';
import Card from '@/components/Card';
import ModalHeader from '@/components/Modal/ModalHeader';
import Typography from '@/components/Typography';
import Grid from '@/components/Grid';
import Form from '@/components/Form';
import { TextInputWithValidation } from '@/components/Input/TextInput';
import { SubmitError } from '@/components/Error';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import { createOrganizationSpot } from '@/services/api/organizations';
import { timeSchema } from '@/validation';

const fields = translations.inputs;

const schema: yup.ObjectSchema<DiningSlotAdd> = yup
  .object({
    startTime: timeSchema.label('Start time').required(),
    endTime: timeSchema.label('End Time').required(),
    limit: yup.number().label('Limit').required()
  })
  .required();

export interface Props extends InstanceProps {
  orgId: string;
  type: 'add' | 'edit';
  onSaved: () => any;
}

const TimeSlotModal: React.FC<Props & WrappedComponentProps> = ({ close, orgId, type, onSaved, intl }) => {
  return (
    <Card className="w-[450px] p-6">
      <Grid.Row>
        <Grid.Column className="w-full">
          <ModalHeader close={close}>
            <Typography is="span" className="font-bold">
              {type === 'add' ? 'Add dining slot' : 'Edit dining slot'}
            </Typography>
          </ModalHeader>

          <Form
            id={type + 'timeslot'}
            schema={schema}
            subscription={{ submitError: true, submitting: true, dirty: true, pristine: true }}
            onSubmit={(values) =>
              createOrganizationSpot(orgId, values).then(() => {
                onSaved();
                close();
              })
            }
            className="mt-8 space-y-4"
          >
            {({ submitError, submitting, dirty, pristine }, id) => (
              <React.Fragment>
                <div className="flex space-x-4">
                  <Form.Field
                    is={TextInputWithValidation}
                    id={`${id}-startTime`}
                    name="startTime"
                    label={intl.formatMessage({ id: fields.startTime.label })}
                    placeholder={intl.formatMessage({ id: fields.startTime.placeholder })}
                    readOnly={submitting}
                  />

                  <Form.Field
                    is={TextInputWithValidation}
                    id={`${id}-endTime`}
                    name="endTime"
                    label={intl.formatMessage({ id: fields.endTime.label })}
                    placeholder={intl.formatMessage({ id: fields.endTime.placeholder })}
                    readOnly={submitting}
                  />
                </div>

                <Form.Field
                  is={TextInputWithValidation}
                  id={`${id}-limit`}
                  name="limit"
                  label={intl.formatMessage({ id: fields.spotLimit.label })}
                  placeholder={intl.formatMessage({ id: fields.spotLimit.placeholder })}
                  readOnly={submitting}
                />

                <SubmitError error={submitError} />

                <Button
                  type="submit"
                  loading={submitting}
                  disabled={!dirty || pristine}
                  appearance="ghost"
                  className="mt-4 w-full"
                >
                  Save
                </Button>
              </React.Fragment>
            )}
          </Form>
        </Grid.Column>
      </Grid.Row>
    </Card>
  );
};

export default injectIntl(TimeSlotModal);
