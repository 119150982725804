import React from 'react';

import { getVendors } from '@/services/api/vendors';

import LoadData from '@/components/LoadData';
import If from '@/components/If';
import Loading from '@/components/Loading';
import { Vendor } from '@/domains';

export interface Props {
  vendors: Vendor[];
  onVendorIdsChanged: (vendors: Vendor[]) => any;
}

const VendorSelectionDropdown: React.FC<Props> = ({ vendors, onVendorIdsChanged }) => {
  return (
    <div className="h-48 w-48 bg-white rounded-md shadow overflow-scroll">
      <ul role="list" className="divide-y divide-gray-200 overflow-scroll p-4 space-y-2">
        <LoadData load={() => getVendors(1, 20, '')}>
          {({ value: result, loading }) => (
            <If
              condition={loading}
              then={() => (
                <Loading visible={true} center className="h-full">
                  <Loading.Indicator size={60} borderWidth={4} />
                </Loading>
              )}
              else={() =>
                result.content.map((vendor) => (
                  <li key={vendor.id}>
                    <label className="flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                        checked={vendors.findIndex((v) => v.id === vendor.id) !== -1}
                        onChange={() => {
                          const newVendors =
                            vendors.findIndex((v) => v.id === vendor.id) !== -1
                              ? vendors.filter((e) => e.id !== vendor.id)
                              : [...vendors, vendor];
                          onVendorIdsChanged(newVendors);
                        }}
                      />
                      <span className="ml-2 text-sm leading-5 font-medium text-gray-900">{vendor.name}</span>
                    </label>
                  </li>
                ))
              }
            />
          )}
        </LoadData>
      </ul>
    </div>
  );
};

export default VendorSelectionDropdown;

{
  /* <li className="py-4 flex">
          <img
            className="h-10 w-10 rounded-full"
            src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            alt=""
          />
          <div className="ml-3">
            <p className="text-sm font-medium text-gray-900">Calvin Hawkins</p>
            <p className="text-sm text-gray-500">calvin.hawkins@example.com</p>
          </div>
        </li> */
}
