import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { urls } from '@/constants';
import ConsumeRedirectRoute from '@/components/Navigation/ConsumeRedirectRoute';

import SignInPage from './SignInPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import AcceptInvitationPage from './AcceptInvitationPage';
import ResetPasswordPage from './ResetPasswordPage';

const Public: React.FC = () => (
  <Switch>
    <Route exact path={urls.signIn} component={SignInPage} />
    <Route exact path={urls.forgotPassword.main} component={ForgotPasswordPage} />
    <Route exact path={urls.resetPassword.main} component={ResetPasswordPage} />
    <Route exact path={urls.acceptInvitation} component={AcceptInvitationPage} />
    <ConsumeRedirectRoute render={() => <ConsumeRedirectRoute render={() => <Redirect to={urls.signIn} />} />} />
  </Switch>
);

export default Public;
