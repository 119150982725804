import React from 'react';

import { urls } from '@/constants';
import { getOrganizations } from '@/services/api/organizations';

import PaginatedTable from '@/components/PaginatedTable';
import Status from '@/components/Status';
import Table from '@/components/Table';
import Typography from '@/components/Typography';
import { Organization } from '@/domains';

const OrganizationsListPage: React.FC = () => (
  <React.Fragment>
    <div className="flex">
      <Typography is="h1" type="header">
        Organizations
      </Typography>
    </div>

    <PaginatedTable
      className="w-full py-4"
      source={(page, number) => getOrganizations(page, number)}
      empty={() => <div>Empty</div>}
      renderHeader={() => (
        <React.Fragment>
          <Table.Header>Name</Table.Header>
          <Table.Header>Status</Table.Header>
        </React.Fragment>
      )}
    >
      {(data) => (
        <React.Fragment>
          {data.map((org: Organization) => (
            <Table.LinkRow key={org.id} hoverable url={urls.organizations.detail.get(org.id)}>
              <Table.Data>
                <div className="flex space-x-4 align-middle">
                  <img src={org.thumbnailUrl} className="object-cover w-10 h-10 bg-gray-200 rounded-lg" />
                  <div>
                    <Typography is="p">{org.name}</Typography>
                    <Typography is="span">{org.address}</Typography>
                  </div>
                </div>
              </Table.Data>
              <Table.Data>
                <Status active={org.active} />
              </Table.Data>
            </Table.LinkRow>
          ))}
        </React.Fragment>
      )}
    </PaginatedTable>
  </React.Fragment>
);

export default OrganizationsListPage;
