import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';

export interface HttpResponse<T> extends AxiosResponse<T> {}
export interface HttpRequestConfig extends AxiosRequestConfig {}

export interface HttpError<T> extends AxiosError<T> {
  config: HttpRequestConfig;
}

export class HttpClient {
  protected client: AxiosInstance;

  constructor(config?: HttpRequestConfig) {
    this.client = axios.create(config);
  }

  protected getReportOptions(error: HttpError<any>) {
    return error.isAxiosError
      ? {
          config: { headers: error.config.headers, data: error.config.data, params: error.config.params },
          response: error.response ? error.response.data : {}
        }
      : {};
  }

  protected unwrap = <T>(response: HttpResponse<T>) => response.data;

  get<T>(url: string, params?: object, config?: HttpRequestConfig) {
    return this.client.get<T>(url, { params, ...config }).then(this.unwrap);
  }

  post<T>(url: string, data?: object, config?: HttpRequestConfig) {
    return this.client.post<T>(url, data, config).then(this.unwrap);
  }

  put<T>(url: string, data?: object, config?: HttpRequestConfig) {
    return this.client.put<T>(url, data, config).then(this.unwrap);
  }

  patch<T>(url: string, data?: object, config?: HttpRequestConfig) {
    return this.client.patch<T>(url, data, config).then(this.unwrap);
  }

  delete<T>(url: string, config?: HttpRequestConfig) {
    return this.client.delete<T>(url, config).then(this.unwrap);
  }
}

export const httpClient = new HttpClient();
