import React from 'react';
import { FormRenderProps } from 'react-final-form';

import { TextInputWithValidation } from '@/components/Input/TextInput';
import Typography from '@/components/Typography';
import If from '@/components/If';
import Grid from '@/components/Grid';
import Form from '@/components/Form';
import { CategoriesConsumer } from '@/components/Context/Categories';
import { ImageInputWithValidation } from '@/components/Input/ImageInput';
import { SubmitError } from '@/components/Error';

export interface Props {
  id: string;
  formProps: FormRenderProps<any>;
  scope: 'create' | 'edit';
}

const MealForm: React.FC<Props> = ({ id, formProps: { submitting, submitError, values, form } }) => {
  return (
    <div className="space-y-8 divide-y divide-gray-200 ">
      <div className="flex flex-col-reverse lg:flex-row lg:space-x-6 ">
        <Grid.Column className="flex-1 space-y-4">
          <SubmitError error={submitError} />

          <Header title="General" />

          <Form.Field
            is={ImageInputWithValidation}
            id={`${id}-image`}
            name="image"
            label="Image"
            title="Image"
            placeholder="Select Image"
            required={true}
            readonly={submitting}
            onImageUploaded={(image) => {
              form.change('image', image);
            }}
          />

          <Form.Field
            is={TextInputWithValidation}
            id={`${id}-title`}
            name="title"
            type="text"
            label="Title"
            placeholder="Enter title"
            required={true}
            readOnly={submitting}
          />

          <Form.Field
            is={TextInputWithValidation}
            id={`${id}-description`}
            inputIs="textarea"
            rows={4}
            name="description"
            type="textarea"
            label="Description"
            placeholder="Add meal description"
            required={true}
            readOnly={submitting}
          />

          <Grid>
            <Grid.Row className="space-x-4">
              <Form.Field
                is={TextInputWithValidation}
                id={`${id}-price`}
                name="price"
                type="text"
                label="Price"
                placeholder="Set price"
                required={true}
                readOnly={submitting}
              />
            </Grid.Row>
          </Grid>

          <div className="pt-6 sm:pt-5">
            <div role="group" aria-labelledby="label-notifications">
              <div>
                <Typography is="h4" type="small-header">
                  Listing status
                </Typography>
              </div>

              <div className="max-w-lg">
                <div className="mt-4 space-y-4">
                  <div className="flex items-center">
                    <input
                      id="push-everything"
                      name="push-notifications"
                      type="radio"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label htmlFor="push-everything" className="ml-3 block text-sm font-medium text-gray-700">
                      Remove from listing
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="push-email"
                      name="push-notifications"
                      type="radio"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label htmlFor="push-email" className="ml-3 block text-sm font-medium text-gray-700">
                      Open for orders
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="push-nothing"
                      name="push-notifications"
                      type="radio"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label htmlFor="push-nothing" className="ml-3 block text-sm font-medium text-gray-700">
                      Open for orders (limit orders per day)
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid.Column>
      </div>
    </div>
  );
};

const Header: React.FC<{ title: string; description?: string }> = ({ title, description }) => {
  return (
    <div className="pb-2">
      <Typography is="h3" type="small-header">
        {title}
      </Typography>
      <If
        condition={description}
        then={() => (
          <Typography is="p" type="sm" className=" text-gray-500">
            {description}
          </Typography>
        )}
      />
    </div>
  );
};

export default MealForm;
