import React from 'react';
import classnames from 'classnames';

import { SidebarProvider } from '@/components/Context/Sidebar';
import { SessionConsumer } from '@/components/Context/Session';

export interface Props {
  className?: string;
}

export interface StaticComponents {
  Header: React.FC;
  Page: React.FC;
  Content: React.FC;
}

const AppLayout: React.FC<Props> & StaticComponents = (props) => {
  return (
    <SidebarProvider isOpen={false}>
      <SessionConsumer>
        {({ me }) => <div className={classnames('flex flex-col h-full', props.className)}>{props.children}</div>}
      </SessionConsumer>
    </SidebarProvider>
  );
};

AppLayout.Page = ({ ...props }) => <div {...props} />;
AppLayout.Header = ({ ...props }) => <div className="flex-shrink-0 z-20 sticky top-0" {...props} />;
AppLayout.Content = ({ ...props }) => <div className="flex flex-col flex-1 " {...props} />;

export default AppLayout;
