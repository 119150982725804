import { Me, UpdatePassword } from '@/domains';
import { authorizedApi } from '@/services/network/authorized-api';

export const getMe = (): Promise<Me> =>
  authorizedApi.get<{ user: Me }>(`/users/me`).then(({ user, ...rest }) => ({
    ...user,
    ...rest
  }));

export const updatePassword = (data: UpdatePassword): Promise<any> => authorizedApi.put(`/users/me/password`, data);

export const resetPassword = (password: String, token: String): Promise<any> =>
  authorizedApi.put('/users/password', {
    password: password,
    token: token
  });
