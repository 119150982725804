import React, { useCallback, useState } from 'react';
import { debounce } from 'lodash';

import Grid from '../Grid';
import If from '../If';

export interface Props {
  onChange: (term: string) => void;
  placeholder: string;
  className?: string;
  showFilter?: boolean;
}

const SearchBar: React.FC<Props> = ({ onChange, className, showFilter, placeholder }) => {
  const handler = useCallback(debounce(onChange, 300), []);
  const [isFilterOpen, setState] = useState(false);

  return (
    <div className={className}>
      <Grid className="flex">
        <input
          type="text"
          placeholder={placeholder}
          className="px-3 py-3 flex-1 w-72 rounded-md bg-white border-transparent focus:border-gray-500 focus:ring-0 text-sm border border-gray-300"
          onChange={(e) => handler(e.target.value)}
        />
        <If
          condition={showFilter}
          then={() => (
            <button className="pl-4" onClick={() => setState(!isFilterOpen)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                />
              </svg>
            </button>
          )}
        />
      </Grid>

      <If
        condition={isFilterOpen}
        then={() => (
          <React.Fragment>
            <div className="flex items-center justify-between mt-4">
              <p className="font-medium">Filters</p>
              <button className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 text-sm font-medium rounded-md">
                Reset Filter
              </button>
            </div>

            <div>
              <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 mt-4">
                <select className="px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm">
                  <option value="">All Statuses</option>
                  <option value="active">Active</option>
                  <option value="unavailable">Unavailable</option>
                </select>
              </div>
            </div>
          </React.Fragment>
        )}
      />
    </div>
  );
};

export default SearchBar;

// export default class SearchBar extends React.PureComponent<Props, State> {
//   state: State = {
//     isFilterOpen: false
//   };

//   handler = (value: string) => useCallback(debounce(this.props.onChange(value), 300), []);
//   onToggleFilter = () => this.setState({ isFilterOpen: !this.state.isFilterOpen });

//   render() {
//     return;
//   }
// }
