import React, { PureComponent } from 'react';
import classnames from 'classnames';

import Row from './Row';
import Column from './Column';

interface Props {
  className?: string;
}
export default class Card extends PureComponent<Props> {
  static Row = Row;
  static Column = Column;

  render() {
    const { className, children } = this.props;
    return (
      <div
        className={classnames(
          'inline-block align-bottom bg-white rounded-lg pt-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full ',
          className
        )}
      >
        {children}
      </div>
    );
  }
}
