import React from 'react';

import { InstanceProps } from '../Modal';

import Card from '@/components/Card';
import Grid from '@/components/Grid';
import ModalHeader from '../ModalHeader';
import Typography from '@/components/Typography';
import { DateRange } from 'react-date-range';
import Button from '@/components/Button';
import { addVendorTimeOff } from '@/services/api/vendors';
import { SubmitError } from '@/components/Error';

export interface Props extends InstanceProps {
  vendorId: string;
  onSaved: () => void;
}

const TimeOffModal: React.FC<Props> = ({ vendorId, onSaved, close }) => {
  const [state, setState] = React.useState({
    isLoading: false,
    error: null,
    range: {
      startDate: new Date(),
      endDate: new Date(),
      key: 'time-off-range'
    }
  });

  return (
    <Card className="w-[450px] p-6">
      <Grid.Row>
        <Grid.Column className="w-full">
          <ModalHeader close={close}>
            <Typography is="span" className="font-bold">
              Add time off
            </Typography>
          </ModalHeader>

          <DateRange
            className="shadow rounded-md mt-4"
            editableDateInputs={true}
            onChange={(item) =>
              setState({
                ...state,
                range: {
                  startDate: item[state.range.key].startDate,
                  endDate: item[state.range.key].endDate,
                  key: item[state.range.key].key
                }
              })
            }
            moveRangeOnFirstSelection={false}
            ranges={[state.range]}
            minDate={new Date()}
          />

          <SubmitError error={state.error} />

          <Button
            type="submit"
            icon="plus"
            appearance="ghost"
            className="mt-4 w-full"
            onClick={() => {
              setState({ ...state, isLoading: true });
              addVendorTimeOff(vendorId, state.range)
                .catch((error) => {
                  setState({ ...state, isLoading: false, error });
                })
                .then(onSaved)
                .then(close);
            }}
          >
            Save
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Card>
  );
};

export default TimeOffModal;
