import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { Role } from '@/constants';
import { translations } from '@/locale';

import Select, { Props as SelectProps } from '@/components/Select';
import { withValidation } from '@/components/hoc/withValidation';

export interface Props extends Omit<SelectProps<Role>, 'items'>, WrappedComponentProps {
  roles: Role[];
}

const RoleSelectInput: React.FC<Props> = ({ intl, roles, ...rest }) => {
  const items = roles.map((role) => ({
    value: role,
    name: intl.formatMessage({ id: translations.roles[role] })
  }));

  return <Select {...rest} items={items} />;
};

const Default = injectIntl(RoleSelectInput);
export const RoleSelectInputWithValidation = withValidation(Default);
export default Default;
