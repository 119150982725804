import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import * as yup from 'yup';

import { translations } from '@/locale';

import Typography from '@/components/Typography';
import LoadData from '@/components/LoadData';
import { getMeal, updateMeal } from '@/services/api/meals';
import If from '@/components/If';
import Loading from '@/components/Loading';
import Placeholder from '@/components/Placeholder';
import Form from '@/components/Form';
import Button from '@/components/Button';
import MealForm from '@/components/Form/MealForm';
import { MealUpdate } from '@/domains';
import { imageSchema } from '@/validation';

const schema: yup.ObjectSchema<MealUpdate> = yup
  .object({
    title: yup.string().label('title').required(),
    description: yup.string().label('description').required(),
    price: yup.number().label('price').required(),
    image: imageSchema.label(translations.inputs.image.label).required()
  })
  .required();

const MealDetailPage: React.FC<RouteComponentProps<{ id: string }> & WrappedComponentProps> = ({ intl, match }) => {
  const mealId = match.params.id;

  return (
    <React.Fragment>
      <LoadData id={mealId} load={getMeal}>
        {({ value: meal, loading }) => (
          <If
            condition={loading}
            then={() => (
              <Loading visible={loading} center className="h-full">
                <Loading.Indicator size={60} borderWidth={4} />
              </Loading>
            )}
            else={() => (
              <If
                condition={!meal}
                then={() => (
                  <Placeholder
                    className="h-full justify-center"
                    image="bell"
                    size="xs"
                    title="Not found"
                    description="Meal not found."
                  />
                )}
                else={() => (
                  <Form
                    id="add-user"
                    schema={schema}
                    initialValues={{
                      title: meal.title,
                      description: meal.description,
                      price: meal.price,
                      image: { url: meal.imageUrl, thumbnailUrl: meal.thumbnailUrl }
                    }}
                    subscription={{ submitError: true, submitting: true, dirty: true, pristine: true }}
                    onSubmit={(values) => updateMeal(mealId, values)}
                    className="mt-8"
                  >
                    {(formRenderProps, id) => (
                      <React.Fragment>
                        <div className="flex justify-between">
                          <div>
                            <Typography is="h1" type="header">
                              {meal.title}
                            </Typography>
                            <Typography is="p" type="sm" className="text-gray-500">
                              Set the availability of this meal.
                            </Typography>
                          </div>
                          <Button type="submit" appearance="ghost" loading={formRenderProps.submitting}>
                            Save
                          </Button>
                        </div>

                        <div className="bg-white overflow-hidden shadow rounded-lg p-4 mt-6">
                          <MealForm id={id} formProps={formRenderProps} scope="edit" />
                        </div>
                      </React.Fragment>
                    )}
                  </Form>
                )}
              />
            )}
          />
        )}
      </LoadData>
    </React.Fragment>
  );
};

export default injectIntl(MealDetailPage);
