import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router';

import { translations } from '@/locale';

import Typography from '@/components/Typography';
import TabbedContainer from '@/components/TabbedContainer';
import Tab from '@/components/TabbedContainer/Tab';
import { urls } from '@/constants';
import MealsListPage from '@/page/Meals/MealsListPage';
import VendorMembersTab from './Tabs/VendorMembersTab';
import VendorAvailabilityTab from '@/page/Vendors/VendorDetailPage/Tabs/VendorAvailabilityTab';
import VendorGeneralTab from '@/page/Vendors/VendorDetailPage/Tabs/VendorGeneralTab';

const text = translations.pages.vendors;

const VendorsDetailPage: React.FC<RouteComponentProps<{ id: string }> & WrappedComponentProps> = ({ intl, match }) => {
  const vendorId = match.params.id;

  return (
    <React.Fragment>
      <div className="flex">
        <Typography is="h1" type="header" className="flex-1">
          Vendor profile
        </Typography>
      </div>
      <div className="bg-white overflow-hidden shadow rounded-lg px-4 mt-6 pb-4">
        <TabbedContainer resourceId={vendorId}>
          <Tab title={intl.formatMessage({ id: text.tabs.general.title })} to={urls.vendors.detail}>
            <VendorGeneralTab id={vendorId} />
          </Tab>

          <Tab title={intl.formatMessage({ id: text.tabs.availability.title })} to={urls.vendors.availability}>
            <VendorAvailabilityTab id={vendorId} />
          </Tab>

          <Tab title={intl.formatMessage({ id: text.tabs.members.title })} to={urls.vendors.members}>
            <VendorMembersTab id={vendorId} />
          </Tab>

          <Tab title={intl.formatMessage({ id: text.tabs.products.title })} to={urls.vendors.meals}>
            <MealsListPage vendorId={vendorId} />
          </Tab>
        </TabbedContainer>
      </div>
    </React.Fragment>
  );
};

export default injectIntl(VendorsDetailPage);
