import React from 'react';

import MenuButton from '@/components/Header/MenuButton';
import { SidebarConsumer } from '@/components/Context/Sidebar';
import ProfileDropdown from '@/components/Header/ProfileDropdown';

import NavigationItem, { Props as NavigationItemProps } from './NavigationItem';
import classNames from 'classnames';

export interface StaticComponents {
  Item: React.FC<NavigationItemProps>;
}

const Header: React.FC & StaticComponents = (props) => (
  <nav className="bg-white shadow">
    <SidebarConsumer>
      {({ isOpen, open, close }) => (
        <React.Fragment>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex justify-between h-16">
              <MenuButton isOpen={isOpen} onClick={isOpen ? close : open} />

              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <img
                    className="block lg:hidden h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                    alt="Workflow"
                  />
                  <h1 className="hidden lg:block w-auto font-bold">BT Food Hall</h1>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-6">{props.children}</div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center sm:static sm:inset-auto sm:ml-6">
                <ProfileDropdown />
              </div>
            </div>
          </div>

          <div className={classNames('sm:hidden', !isOpen && 'hidden')}>
            <div className="pt-2 px-2 pb-4 space-y-2 flex flex-col">{props.children}</div>
          </div>
        </React.Fragment>
      )}
    </SidebarConsumer>
  </nav>
);

Header.Item = NavigationItem as any;
export default Header;
